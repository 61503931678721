import "./Payment.scss";
import Status from "../Status/Status";
import {formatDate, formatTime} from "../../../../../../shared/functions/formateDate";

const Payment = ({ payment, idx }) => {
    return (
        <div className={`payment ${idx % 2  ? "light" : "dark"}`}>
            <div className="date">
                <div>
                    <div>
                        <strong>
                            { formatDate(payment.createdAt) }
                        </strong>
                    </div>
                    <div>
                        { formatTime(payment.createdAt) }
                    </div>
                </div>
            </div>
            <div className="id">
                    {/*{ payment.status }*/}
                <Status payment={payment.status} />
            </div>
            <div className="count d-flex ai-center just-content-space-between">
                <span className="payment-plus">+ { payment.amount }</span>
                <strong>
                    / 0 ₽
                </strong>
                <div> </div>
            </div>
            <strong className="system">
                { payment.paymentSystem }
            </strong>
        </div>
    );
};

export default Payment;