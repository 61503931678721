import "./ButtonPagination.scss";
interface IButton {
    active: number,
    setActive: (number) => void
    value: number
}
const ButtonPagination = (props: IButton) => {
    const {active, pageNumber, setActive} = props;
    return (
        <button
            className={`button-pagination ${active === pageNumber && "active"}`}
            onClick={() => setActive(pageNumber)}
        >
            { pageNumber }
        </button>
    );
};

export default ButtonPagination;