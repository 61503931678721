
const CardProductPersonal = ({ data }) => {
    return (
        <div className="card-product-personal">
            <img className="product-img"  src={data.imageUrl} alt="product" />
            <span className="product-server-profile">{data.name}</span>
        </div>
    );
};

export default CardProductPersonal;