import ProductCardProfile from "../../../../components/ProductCardProfile";

const ActivatedServices = ({ purchasesServices }) => {
    return (
        !!purchasesServices.length &&
            <div className="basket-wrapper">
                <h4 className="title-in-child">Активированые услуги</h4>
                <div className="basket-wrapper">
                    { purchasesServices.map((product, idx) => {
                        return (
                            <ProductCardProfile key={idx} data={product}/>
                        )})
                    }
                </div>
            </div>
    );
};

export default ActivatedServices;