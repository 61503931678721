import {useEffect, useState} from "react";
import {PATHS} from "../../../../../../../enum";
import {Link} from "react-router-dom";
import Payment from "../Payment/Payment";
import {usePaymentsStore} from "../../../../../../../modules/profile/payments/store";
import Pagination from "../../../../../../shared/Pagination/Pagination";

const PaymentsList = ({ resultPayments }) => {
    const fetchPayments = usePaymentsStore(state => state.fetchPayments)
    const totalItems = usePaymentsStore(state => state.totalItems)
    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
       fetchPayments(activePage).then(r => console.log(r));
    }, [activePage])

    return (
        <>
            { !resultPayments.length ? <div className="no-payments">
                <h3>
                    У вас пока не было ни одного платежа <span>😕</span>
                </h3>
                <p>
                    Давай исправим это прямо сейчас!
                </p>
                <Link className='create-set-btn' to={PATHS.DEPOSIT}>
                    Пополнить баланс
                </Link>
            </div>
                : <div className="payments-list-block">
                    <div className="d-flex payments-title">
                        <div className="date d-flex just-content-center">
                            Дата
                        </div>
                        <div className="id d-flex just-content-center">
                            Статус
                        </div>
                        <div className="count d-flex just-content-center">
                            Сумма | Бонус
                        </div>
                        <div className="system d-flex just-content-center">
                            Система
                        </div>
                    </div>
                        {
                            resultPayments.map((payment, idx) => {
                                return(
                                    <Payment key={idx} idx={idx} payment={payment} />
                                )
                            })
                        }
                        <div style={{marginTop: "auto"}}>
                            <Pagination totalItems={totalItems} active={activePage} setActive={setActivePage}/>
                        </div>
                </div>
            }
        </>
    );
};

export default PaymentsList;