import {ICategory} from "../Categories/Categories";

export const categories: ICategory[] = [
    {
        id: "1",
        name: "Все",
    },
    {
        id: "2",
        name: "Автомобили",
    },
    {
        id: "3",
        name: "Экипировка"
    },
    {
        id: "4",
        name: "Стройматериалы"
    }
]
export const dataServerList = [
    {
        id: "1",
        name: "Lite"
    },
    {
        id: "2",
        name: "Casual"
    },
    {
        id: "3",
        name: "Livonia"
    }
]
export const dataPurchaseType = [
    {
        id: "self",
        name: "Для себя"
    },
    {
        id: "friend",
        name: "Для друга"
    }
]

export const testProducts = [
    {
        id: "1",
        price: 300
    },
    {
        id: "2",
        price: 300
    },
    {
        id: "3",
        discount: 0.1,
        price: 300
    },
    {
        id: "4",
        price: 100
    },
    {
        id: "5",
        price: 300
    },
    {
        id: "6",
        discount: 0.1,
        price: 200
    },
    {
        id: "7",
        discount: 0.5,
        price: 400
    },
    {
        id: "8",
        discount: 0.4,
        price: 300
    }
]

export const testProductsActive = [
    {
        id: "1",
        endDate: 6
    },
    {
        id: "2",
        endDate: 3
    },
    {
        id: "3",
        endDate: 2
    },
    {
        id: "4",
        endDate: 1
    }
]

