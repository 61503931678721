import "./ProfilePage.scss";
import Sidebar from "./components/Sidebar/Sidebar";
import ContentProfile from "./components/ContentProfile/ContentProfile";

const ProfilePage = () => {
    return (
        <div className="container flex-direction-column">
            <div className="d-flex margin-top-profile-page">
                <Sidebar />
                <ContentProfile />
            </div>
        </div>
    );
};

export default ProfilePage;