import { create } from 'zustand'
import axios from "axios";
import {PATHS_API} from "../../../enum";

type Server = {
    _id: string
    ip: string
    port: string
    name: string
}

type Store = {
    servers: Server[]
    fetchServers: any
}

export const useServersStore = create<Store>((set) => ({
    servers: [],
    isLoading: false,
    errors: [],
    fetchServers: async () => {
        const result = await axios.get( PATHS_API.SERVERS, {
            withCredentials: true
        })
        set({servers: result.data})
    }
}))