import {useUserStore} from "../../../../../modules/shop/user/store";

const AboutAccount = () => {
    const user = useUserStore(store => store.user)

    return (
        <div>
            Ваш steamId - { user.steamId }
        </div>
    );
};

export default AboutAccount;