import {useState} from "react";
import axios from "axios";
import InputDefault from "../../../../../../shared/Inputs/InputDefault";
import {PATHS_API} from "../../../../../../../enum";

interface ICreateServer {
    name: string,
    ip: string,
    port: string
}

const CreateServer = () => {
    const [formDataServer, setFormDataServer] = useState<ICreateServer>({
        name: "",
        ip: "",
        port: ""

    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormDataServer({ ...formDataServer, [name]: value });
    };

    const handleSubmit = async () => {
        if (formDataServer.name.trim() === "") {
            console.error("Имя не может быть пустым");
            return;
        }

        try {
            const response = await axios.post( PATHS_API.SERVERS, formDataServer, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });

            if (response.status === 201) {
                console.log('Сервер успешно создан!');
                setFormDataServer({
                    name: "",
                    ip: "",
                    port: ""
                }); // Очистка формы
            } else {
                console.error('Ошибка при отправке данных:', response.statusText);
            }
        } catch (error) {
            console.error("Ошибка:", error.response ? error.response.data : error.message);
        }
    };

    return (
        <>
            <h4>Создание сервера</h4>
            <div className="admin-wrapper d-flex gap12">
                <div className="admin-wrapper-block">
                    <form onSubmit={async (e) => {
                        e.preventDefault();
                        await handleSubmit();
                    }}>
                        <div className="d-flex flex-direction-column gap12">
                            <InputDefault onChange={handleChange} name={"name"} value={formDataServer.name} placeholder={"Название сервера"} />
                            <InputDefault onChange={handleChange} name={"ip"} value={formDataServer.ip} placeholder={"IP сервера"} />
                            <InputDefault onChange={handleChange} name={"port"} value={formDataServer.port} placeholder={"PORT сервера"} />
                            <button type="submit" className="button-buy">Создать</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default CreateServer;