import "./Sidebar.scss";
import {Link, useLocation} from 'react-router-dom';
import {useContext} from "react";
import {ThemeContext} from "../../../../providers/ThemeProvider";
import {enumTheme, PATHS_API} from "../../../../../enum";
import {PATHS} from "../../../../routers/utils/Paths.links";
import {useUserStore} from "../../../../../modules/shop/user/store";

export enum CHILD_PROFILE_PATHS {
    BASKET = '/basket',
    PERSONAL_BONUS = '/personal-bonus',
    PERSONAL_PRODUCTS = '/personal-products',
    TRANSFER = '/transfer',
    PAYMENTS = '/payments',
    REFERRAL_PROGRAM = '/referral',
    BONUS_CODE = '/bonus-code',
    INDIVIDUAL_SET = '/custom-set',
    ACCOUNT = '/account',
    ADMIN_PANEL = '/admin-panel',

}

export const dataForProfile = [
    {
        to: CHILD_PROFILE_PATHS.BASKET,
        text: "Корзина",
        svg: <path d="M22,8.5H19.535l-3.7-5.555a1,1,0,0,0-1.664,1.11L17.132,8.5H6.868L9.832,4.055a1,1,0,0,0-1.664-1.11L4.465,8.5H2a1,1,0,0,0,0,2H3v8a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3v-8h1a1,1,0,0,0,0-2Zm-3,10a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1v-8H19ZM7,17V13a1,1,0,0,1,2,0v4a1,1,0,0,1-2,0Zm4,0V13a1,1,0,0,1,2,0v4a1,1,0,0,1-2,0Zm4,0V13a1,1,0,0,1,2,0v4a1,1,0,0,1-2,0Z"/>
    },
    {
        to: CHILD_PROFILE_PATHS.PAYMENTS,
        text: "Платежи",
        svg: <path d="M22,4H2A1,1,0,0,0,1,5V19a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V5A1,1,0,0,0,22,4ZM21,16.184A2.987,2.987,0,0,0,19.184,18H4.816A2.987,2.987,0,0,0,3,16.184V7.816A2.987,2.987,0,0,0,4.816,6H19.184A2.987,2.987,0,0,0,21,7.816ZM12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14ZM7,12a1,1,0,0,1-1,1H5a1,1,0,0,1,0-2H6A1,1,0,0,1,7,12Zm13,0a1,1,0,0,1-1,1H18a1,1,0,0,1,0-2h1A1,1,0,0,1,20,12Z"/>
    },
    {
        to:  CHILD_PROFILE_PATHS.PERSONAL_BONUS,
        text: "Личный бонус",
        svg: <path d="M22,7H20.894A4.192,4.192,0,0,0,21,6a3.718,3.718,0,0,0-4-4c-2.164,0-3.969,2.271-5,3.928C10.969,4.271,9.164,2,7,2A3.718,3.718,0,0,0,3,6a4.192,4.192,0,0,0,.106,1H2A1,1,0,0,0,1,8v5a1,1,0,0,0,1,1H3v7a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V14h1a1,1,0,0,0,1-1V8A1,1,0,0,0,22,7ZM7,4c1.113,0,2.419,1.583,3.308,3H7.556C5,7,5,6.6,5,6,5,4.208,6.142,4,7,4Zm4,16H5V14h6Zm0-8H3V9h8Zm6-8c.858,0,2,.208,2,2,0,.6,0,1-2.556,1H13.692C14.581,5.583,15.887,4,17,4Zm2,16H13V14h6Zm2-8H13V9h8Z"/>
    },
    // {
    //     to:  CHILD_PROFILE_PATHS.PERSONAL_PRODUCTS,
    //     text: "Личные товары",
    //     svg: <path d="M3,22H21a1,1,0,0,0,.994-1.11l-2-18A1,1,0,0,0,19,2H5a1,1,0,0,0-.994.89l-1.556,14-.444,4A1,1,0,0,0,3,22ZM5.9,4H18.1l1.334,12H4.562ZM4.34,18H19.66l.223,2H4.117ZM16,7V9A4,4,0,0,1,8,9V7a1,1,0,0,1,2,0V9a2,2,0,0,0,4,0V7a1,1,0,0,1,2,0Z"/>
    // },
    // {
    //     to: CHILD_PROFILE_PATHS.INDIVIDUAL_SET,
    //     text: "Индивидуальный сет",
    //     svg: <path d="M22,3H2A1,1,0,0,0,1,4v9a1,1,0,0,0,1,1,6.023,6.023,0,0,1,6,6,1,1,0,0,0,1,1h6a1,1,0,0,0,1-1,6.024,6.024,0,0,1,6-6,1,1,0,0,0,1-1V4A1,1,0,0,0,22,3ZM21,5V7H3V5Zm-3.689,8.525A7.224,7.224,0,0,1,15.954,9H21v3.062A8.557,8.557,0,0,0,17.311,13.525Zm-10.621,0A8.445,8.445,0,0,0,3,12.062V9H8.049A7.172,7.172,0,0,1,6.69,13.526ZM14.062,19H9.937a7.926,7.926,0,0,0-1.809-4.1A9.068,9.068,0,0,0,10.046,9h3.908a9.064,9.064,0,0,0,1.918,5.9A7.951,7.951,0,0,0,14.062,19Z"/>
    // },
    {
        to: CHILD_PROFILE_PATHS.REFERRAL_PROGRAM,
        text: "Реферальная программа",
        svg: <path d="M19.73,16.663A3.467,3.467,0,0,0,20.5,14.5a3.5,3.5,0,0,0-7,0,3.467,3.467,0,0,0,.77,2.163A6.04,6.04,0,0,0,12,18.69a6.04,6.04,0,0,0-2.27-2.027A3.467,3.467,0,0,0,10.5,14.5a3.5,3.5,0,0,0-7,0,3.467,3.467,0,0,0,.77,2.163A6,6,0,0,0,1,22a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1A6,6,0,0,0,19.73,16.663ZM7,13a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,7,13ZM3.126,21a4,4,0,0,1,7.748,0ZM17,13a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,17,13Zm-3.874,8a4,4,0,0,1,7.748,0ZM6,2V8A1,1,0,0,0,7,9H9.865l1.367,1.641a1,1,0,0,0,1.536,0L14.135,9H17a1,1,0,0,0,1-1V2a1,1,0,0,0-1-1H7A1,1,0,0,0,6,2ZM8,3h8V7H13.667a1,1,0,0,0-.769.359L12,8.438l-.9-1.079A1,1,0,0,0,10.333,7H8Z"/>
    },
    {
        to: CHILD_PROFILE_PATHS.TRANSFER,
        text: "Перевод баланса",
        svg: <path d="M21,11.184V7a1,1,0,0,0-1-1H4A1,1,0,0,1,4,4H20a1,1,0,0,0,0-2H4A3,3,0,0,0,1,5V19a3,3,0,0,0,3,3H20a1,1,0,0,0,1-1V16.816a2.982,2.982,0,0,0,0-5.632ZM19,20H4a1,1,0,0,1-1-1V7.816A2.966,2.966,0,0,0,4,8H19v3H16a3,3,0,0,0,0,6h3Zm1-5H16a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Z"/>
    },
    {
        to: CHILD_PROFILE_PATHS.BONUS_CODE,
        text: "Бонус код",
        svg: <path d="M9,10a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V11A1,1,0,0,0,9,10Zm12,1a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H3A1,1,0,0,0,2,6v4a1,1,0,0,0,1,1,1,1,0,0,1,0,2,1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1,1,1,0,0,1,0-2ZM20,9.18a3,3,0,0,0,0,5.64V17H10a1,1,0,0,0-2,0H4V14.82A3,3,0,0,0,4,9.18V7H8a1,1,0,0,0,2,0H20Z"/>

    }
]

export const dataForProfileAccount = [
    {
        to: CHILD_PROFILE_PATHS.ACCOUNT,
        text: "Об аккаунте",
        svg: <path d="M12,11A5,5,0,1,0,7,6,5.006,5.006,0,0,0,12,11Zm0-8A3,3,0,1,1,9,6,3,3,0,0,1,12,3ZM4,23H20a1,1,0,0,0,1-1V18a5.006,5.006,0,0,0-5-5H8a5.006,5.006,0,0,0-5,5v4A1,1,0,0,0,4,23Zm1-5a3,3,0,0,1,3-3h8a3,3,0,0,1,3,3v3H5Z"/>
    },
    {
        to: CHILD_PROFILE_PATHS.ADMIN_PANEL,
        text: "Админ панель",
        svg: <path d="M12,16a4,4,0,1,0-4-4A4,4,0,0,0,12,16Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,12,10ZM3.5,12.877l-1,.579a2,2,0,0,0-.733,2.732l1.489,2.578A2,2,0,0,0,5.99,19.5L7,18.916a1.006,1.006,0,0,1,1.008.011.992.992,0,0,1,.495.857V21a2,2,0,0,0,2,2h3a2,2,0,0,0,2-2V19.782a1.009,1.009,0,0,1,1.5-.866l1.009.582a2,2,0,0,0,2.732-.732l1.488-2.578a2,2,0,0,0-.733-2.732l-1-.579a1.007,1.007,0,0,1-.5-.89,1,1,0,0,1,.5-.864l1-.579a2,2,0,0,0,.733-2.732L20.742,5.234A2,2,0,0,0,18.01,4.5L17,5.083a1.008,1.008,0,0,1-1.5-.867V3a2,2,0,0,0-2-2h-3a2,2,0,0,0-2,2V4.294a.854.854,0,0,1-.428.74l-.154.089a.864.864,0,0,1-.854,0L5.99,4.5a2,2,0,0,0-2.733.732L1.769,7.813A2,2,0,0,0,2.5,10.544l1,.578a1.011,1.011,0,0,1,.5.891A.994.994,0,0,1,3.5,12.877Zm1-3.487-1-.578L4.99,6.234l1.074.62a2.86,2.86,0,0,0,2.85,0l.154-.088A2.863,2.863,0,0,0,10.5,4.294V3h3V4.216a3.008,3.008,0,0,0,4.5,2.6l1.007-.582L20.5,8.812l-1,.578a3.024,3.024,0,0,0,0,5.219l1,.579h0l-1.488,2.578L18,17.184a3.008,3.008,0,0,0-4.5,2.6V21h-3V19.784a3.006,3.006,0,0,0-4.5-2.6l-1.007.582L3.5,15.188l1-.579a3.024,3.024,0,0,0,0-5.219Z"/>
    }
]

const Sidebar = () => {
    const user = useUserStore(state => state.user)
    const [theme, setTheme] = useContext(ThemeContext);
    const location = useLocation();

    const colorIcon = theme === enumTheme.DARK_THEME ? "#fff" : "#333"

    return (
        <div className="sidebar">
            <h3 className="sidebar-title">
                Общее
            </h3>
            { dataForProfile.map((item, idx ) =>
                <Link key={idx} to={PATHS.PROFILE + item.to} >
                    <div key={idx} className={`sidebar-item-wrapper ${location.pathname === PATHS.PROFILE + item.to && "active"} d-flex ai-center`}>
                        <div className="sidebar-svg">
                            <svg fill={`${location.pathname === PATHS.PROFILE + item.to ? "#3579F6FF" : colorIcon}`} width="21px" height="21px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                {item.svg}
                            </svg>
                        </div>
                        <div className={`sidebar-item ${location.pathname === PATHS.PROFILE + item.to && "active"}`} >
                            { item.text }
                        </div>
                    </div>
                </Link>
            )}
            <div style={{marginTop: "40px"}}>
                <h3 className="sidebar-title">
                    Аккаунт
                </h3>
                { user.steamId === "76561199105717168" && dataForProfileAccount.map((item, idx ) =>
                    <div key={idx} className="sidebar-item-wrapper d-flex ai-center">
                        <div className="sidebar-svg">
                            <svg fill={`${location.pathname === PATHS.PROFILE + item.to ? "#3579F6FF" : colorIcon}`} width="21px" height="21px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                {item.svg}
                            </svg>
                        </div>
                        <Link
                            key={idx}
                            to={PATHS.PROFILE + item.to}
                            className={`sidebar-item ${location.pathname === PATHS.PROFILE + item.to && "active"}`}>
                            { item.text }
                        </Link>
                    </div>
                )}
                <div className="sidebar-item-wrapper d-flex ai-center">
                    <div className="sidebar-svg">
                        <svg fill={colorIcon} width="21px" height="21px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.707,8.707,5.414,11H17a1,1,0,0,1,0,2H5.414l2.293,2.293a1,1,0,1,1-1.414,1.414l-4-4a1,1,0,0,1,0-1.414l4-4A1,1,0,1,1,7.707,8.707ZM21,1H13a1,1,0,0,0,0,2h7V21H13a1,1,0,0,0,0,2h8a1,1,0,0,0,1-1V2A1,1,0,0,0,21,1Z"/>
                        </svg>
                    </div>
                    <a className="sidebar-item" onClick={() => localStorage.removeItem("isAuth")} href={ PATHS_API.LOGOUT }>Выйти с аккаунта</a>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;