import { create } from 'zustand'
import axios from "axios";
import { PATHS_API } from "../../../enum";

type Payment = {
    amount: number;
    createdAt: string;
    currency: string;
    orderId: string;
    paymentId: string;
    paymentSystem: string;
    preferMethod: string;
    status: string;
    steamId: string;
    updatedAt: Date;
    _id: string;
};

type Store = {
    payments: Payment[]; // Исправлено: переименовано с transfers на payments
    totalItems: number;
    isLoading: boolean;
    errors: string[];
    fetchPayments: (activePage: number) => Promise<void>; // Типизировано
};

export const usePaymentsStore = create<Store>((set) => ({
    payments: [],
    totalItems: 0,
    isLoading: false,
    errors: [],
    fetchPayments: async (activePage: number) => {
        try {
            const result = await axios.get(
                `${PATHS_API.PAYMENTS}?page=${activePage}&limit=6`,
                {
                    withCredentials: true,
                }
            );
            set({ payments: result.data.payments, totalItems: result.data.totalItems });
        } catch (error) {
            set({ errors: [error.message] });
        }
    },
}));