
const SocialIcons = () => {
    return (
        <div className="social-icons">
            <div>
                <svg width="30" height="30" viewBox="0 0 24 24" fill="#fff">
                    <g clip-path="url(#vk_svg__vk_svg__clip0_8450_11105)">
                        <path
                            d="M23.59 4.293A4.363 4.363 0 0019.707.412a72.277 72.277 0 00-15.408 0A4.364 4.364 0 00.41 4.293a72.613 72.613 0 000 15.413 4.37 4.37 0 003.883 3.882 72.29 72.29 0 0015.408 0 4.372 4.372 0 003.889-3.883 72.608 72.608 0 000-15.412zM18.78 16.29c-.348.05-2.055 0-2.144 0a1.676 1.676 0 01-1.186-.473c-.36-.347-.682-.73-1.034-1.09a2.768 2.768 0 00-.337-.306c-.28-.215-.559-.166-.69.165a6.434 6.434 0 00-.216 1.095c-.024.334-.236.545-.613.567a8.502 8.502 0 01-.696.012 5.319 5.319 0 01-2.412-.597 6.565 6.565 0 01-2.063-1.802c-.91-1.162-1.637-2.438-2.286-3.753-.034-.067-.7-1.48-.716-1.549-.06-.222 0-.436.185-.511.117-.045 2.31 0 2.346 0a.77.77 0 01.736.53c.406 1.038.96 2.01 1.648 2.888.096.116.208.219.334.304.178.122.347.08.421-.127a3.6 3.6 0 00.153-.88c.012-.591 0-.98-.033-1.571-.022-.38-.155-.71-.715-.818-.171-.032-.186-.174-.076-.315.23-.293.546-.341.899-.358.526-.03 1.052-.01 1.579 0h.114c.23-.002.458.021.682.069a.631.631 0 01.5.56c.027.15.039.3.034.453-.013.644-.045 1.29-.051 1.936-.007.255.017.511.07.762.074.345.31.429.545.18.304-.319.582-.662.833-1.024a10.33 10.33 0 001.09-2.122c.153-.383.273-.467.682-.467h2.326a1.38 1.38 0 01.41.054.353.353 0 01.244.457c-.115.511-.39.945-.682 1.363-.473.67-.972 1.323-1.456 1.984a2.557 2.557 0 00-.168.273c-.183.328-.17.512.095.785.423.435.874.841 1.282 1.288.299.326.57.676.807 1.049.299.463.115.905-.442.984l.002.005z"
                            fill="var(--color-secondary-dark)"></path>
                    </g>
                    <defs>
                        <clipPath id="vk_svg__vk_svg__clip0_8450_11105">
                            <path fill="#fff" d="M0 0h24v24H0z"></path>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div>
                <svg width="30" height="30" viewBox="0 0 24 24" fill="#fff">
                    <g clip-path="url(#telegram_svg__telegram_svg__clip0_8355_18920)" fill="var(--color-secondary-dark)">
                        <path
                            d="M15.342 7.978l-6.605 4.068a.665.665 0 00-.29.748l.713 2.51c.051.177.31.159.334-.025l.186-1.376a1.13 1.13 0 01.35-.676l5.45-5.067c.102-.094-.02-.255-.139-.182z"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M23.59 4.293a4.2 4.2 0 00-.245-1.045 4.293 4.293 0 00-1.01-1.583A4.37 4.37 0 0019.703.412a72.27 72.27 0 00-15.408 0A4.36 4.36 0 00.41 4.293a72.59 72.59 0 000 15.413 4.35 4.35 0 001.256 2.627 4.353 4.353 0 002.625 1.255c5.12.55 10.287.55 15.408 0a4.371 4.371 0 002.63-1.254 4.388 4.388 0 001.26-2.628 72.595 72.595 0 000-15.413zM7.64 12.717l-3.38-1.261a.399.399 0 01-.004-.745l13.87-5.35a.398.398 0 01.534.452l-2.49 11.972a.398.398 0 01-.623.241l-3.398-2.467a.562.562 0 00-.685.02L9.58 17.113a.398.398 0 01-.632-.19l-1.309-4.207z"></path>
                    </g>
                    <defs>
                        <clipPath id="telegram_svg__telegram_svg__clip0_8355_18920">
                            <path fill="#fff" d="M0 0h24v24H0z"></path>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div>
                <svg width="30" height="30" viewBox="0 0 24 24" fill="#fff">
                    <g clip-path="url(#discord_svg__discord_svg__clip0_8450_11107)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M19.707 23.59a72.608 72.608 0 01-15.408 0 4.372 4.372 0 01-3.89-3.883 72.622 72.622 0 010-15.408A4.373 4.373 0 014.294.409a72.621 72.621 0 0115.408 0 4.373 4.373 0 013.89 3.884 72.608 72.608 0 010 15.408 4.372 4.372 0 01-3.884 3.89zM14.288 5.334c1.143.195 2.237.534 3.257.994a.04.04 0 01.02.019c1.807 2.615 2.7 5.565 2.366 8.96a.05.05 0 01-.02.037 13.31 13.31 0 01-4.002 1.987.051.051 0 01-.057-.019c-.302-.413-.576-.85-.816-1.307a.05.05 0 01.027-.07c.436-.162.85-.356 1.249-.585a.05.05 0 00.004-.083 6.293 6.293 0 01-.248-.192.05.05 0 00-.053-.006c-2.587 1.176-5.422 1.176-8.04 0a.05.05 0 00-.052.006c-.08.065-.164.13-.248.192a.05.05 0 00.005.083c.398.225.813.423 1.248.586a.05.05 0 01.028.069 9.3 9.3 0 01-.818 1.308.052.052 0 01-.056.018 13.355 13.355 0 01-3.995-1.987.054.054 0 01-.02-.037c-.28-2.937.289-5.912 2.363-8.96a.046.046 0 01.021-.019c1.02-.46 2.114-.8 3.257-.994a.052.052 0 01.052.025c.142.246.303.561.412.82a12.376 12.376 0 013.658 0 9.04 9.04 0 01.406-.82.05.05 0 01.052-.025zm-6.38 6.598c0 .874.65 1.586 1.439 1.586.8 0 1.438-.712 1.438-1.586.012-.869-.631-1.587-1.438-1.587-.801 0-1.438.712-1.438 1.587zm5.317 0c0 .874.65 1.586 1.438 1.586.807 0 1.438-.712 1.438-1.586.013-.869-.63-1.587-1.438-1.587-.8 0-1.438.712-1.438 1.587z"
                              fill="var(--color-secondary-dark)"></path>
                    </g>
                    <defs>
                        <clipPath id="discord_svg__discord_svg__clip0_8450_11107">
                            <path fill="#fff" d="M0 0h24v24H0z"></path>
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
    );
};

export default SocialIcons;