import AgreementPage from "../../pages/AgreementPage/AgreementPage";

export enum PATHS {
    SHOP = "/",
    DEPOSIT = "/deposit",
    AGREEMENT = "/agreement",
    PRIVACY = "/privacy",
    CUSTOM = "/custom",
    LOGOUT = "/logout",
    LOGIN = "/auth/steam/",
    PROFILE = "/profile",
    SETTINGS = "/settings",
    DISCORD = "https://discord.gg/alcatraz-dayz",
    VK = "https://vk.com/dayz_alcatraz"
}

export enum URL {
    FRONT = "http://localhost:5173/",
    BACKEND = "http://localhost:5000/",
}