
const LevelBlock = ({user}) => {
    return (
        <div className="d-flex ai-center">
            <div className="level">
                {(user.personalBonus + 1) || 0}
            </div>
            <div className="level-name">
                Новичок
            </div>
        </div>
    );
};

export default LevelBlock;