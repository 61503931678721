import "./DropDownNav.scss";
import {useRef, useState} from "react";
import {useClickOutside} from "../../../hooks/useClickOutside";

interface IOption {
    id: string,
    name: string
}
interface IDropDownPurchase {
    data: IOption[],
    width: string,
    activeOption: IOption,
    setActiveOption: (IOption) => void,
}

const DropDownNav = (props: IDropDownPurchase) => {
    const [isOpen, setOpen] = useState(false);
    const menuRef = useRef(null);

    useClickOutside(menuRef, () => {
        if (isOpen) setTimeout(() => setOpen(false), 50);
    });

    return (
        <div className="header">
            <button
                className="DropDownLang DropDownNav"
                onClick={() => setOpen(!isOpen)}
                style={{width: props.width, outline: "none"}}
                ref={menuRef}
            >
                {props.activeOption.name}
            </button>
            <nav
                className={`menu menuNav ${isOpen ? "active" : ""}`}
                style={{width: props.width}}
            >
                <ul className="menu__list">
                    {props.data.map((option, idx) => {
                        return (
                            <li
                                key={idx}
                                className="menu__item"
                                onClick={() => {props.setActiveOption(option), setOpen(false)}}
                            >
                                {option.name}
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </div>
    );
};

export default DropDownNav;