import InputDefault from "../../../../shared/Inputs/InputDefault";
import "./BonusCode.scss";
import {useEffect, useState} from "react";
import axios from "axios";
import {useUserStore} from "../../../../../modules/shop/user/store";
import Toaster from "../../../../shared/Toaster";
import NoBonusCode from "./componetns/NoBonusCode/NoBonusCode";
import {PATHS_API} from "../../../../../enum";
import {ToasterProps} from "../../../../shared/Toaster/Toaster";

const BonusCode = () => {
    const [bonusCode, setBonusCode] = useState("")
    const [useBonusCode, setUseBonusCode] = useState(false)
    const fetchUser = useUserStore(store => store.fetchUser)

    const [toastProps, setToastProps] = useState<ToasterProps | null>(null);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if(useBonusCode) {
            if(bonusCode === "") {
                return;
            }

            if (isLoading) return; // предотвращает повторные клики
            setIsLoading(true);

            axios.get(PATHS_API.BONUSCODE + `/${bonusCode}`, {
                withCredentials: true
            })
                .then((data) => {
                    setToastProps({
                        message: data.data.message,
                        type: data.data.status === 201 ? "success" : "error",
                        id: data.data.id
                    })
                })
                .then(() => {
                    fetchUser()
                    setUseBonusCode(false)
                    setBonusCode("")
                })
                .catch((error) => {
                    // В случае ошибки показываем сообщение об ошибке
                    const errorMessage = error.response?.data?.message || "Произошла ошибка";
                    setToastProps({ message: errorMessage, type: "error", id: error.response?.data?.id });
                    setUseBonusCode(false)
                    setBonusCode("")
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [useBonusCode])


    return (
        <div className="bonus-code-profile-child">
            <div className="wrapper-input-bonusCode">
                <InputDefault value={bonusCode} setValue={setBonusCode} placeholder={"example - wipe2024"} />
                {/*<div className="clue">Где взять код?</div>*/}
            </div>
            <div className="wrapper-button-bonusCode">
                <button onClick={() => setUseBonusCode(true)} className="button-buy">
                    { isLoading ? "Ожидание ответа..." : "Использовать" }
                </button>
            </div>
            <NoBonusCode />
            {toastProps && <Toaster message={toastProps.message} type={toastProps.type} id={toastProps.id} />}
        </div>
    );
};

export default BonusCode;