export enum enumTheme {
    THEME = "theme",
    DARK_THEME = "dark",
    LIGHT_THEME = "light"
}

export enum CATEGORY_NAME {
    ALL = "Все"
}

export enum SERVER_NAME {
    ALL = "Все сервера"
}

export enum BASE {
    URL = "http://localhost:5000/",
}

export enum PATHS_API {
    PRODUCTS = "/api/products",
    PAYMENTS = "/api/payment/all",
    LOCATION = "/api/location-geo",
    PERSONAL = "/api/products/personal",
    CATEGORIES = "/api/categories",
    SERVERS = "/api/servers",
    PURCHASES = "/api/purchases",
    BONUSCODE = "/api/bonuscode",
    SELF = "/api/users/self",
    TRANSFER = "/api/transfers/balance",
    TRANSFERS = "/api/transfers",
    LOGOUT = "/api/logout",
    LOGIN = "/api/auth/steam/",
    SAVE_EMAIL = "/api/users/email",
}

export enum ADMIN {
    SERVER = "server",
    CATEGORY = "category",
    PRODUCT = "product",
}

export enum PATHS {
    DEPOSIT = "/deposit",
    SHOP = "/",
    CUSTOM = "/custom",
    LOGOUT = "/logout",
    LOGIN = "/auth/steam/",
    PROFILE = "/profile",
    AGREEMENT = "/agreement",
    SETTINGS = "/settings",
    DISCORD = "https://discord.gg/alcatraz-dayz",
    VK = "https://vk.com/dayz_alcatraz"
}
