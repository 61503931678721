import {useState, useRef} from 'react';
import "./DropDownPurchase.scss";
import {useClickOutside} from "../../../hooks/useClickOutside";

export interface IOption {
    _id: string | number,
    name: string,
    owners?: string[]
}
export interface IDropDownPurchase {
    data: IOption[],
    width: string,
    activeOption: IOption,
    setActiveOption: (IOption) => void,
}

const DropDownPurchase = (props: IDropDownPurchase) => {
    const [isOpen, setOpen] = useState(false);
    const menuRef = useRef(null);

    useClickOutside(menuRef, () => {
        if (isOpen) setTimeout(() => setOpen(false), 50);
    });

    return (
        <div className="header-drop">
            <button
                className="DropDownLang DropDownPurchase"
                onClick={() => setOpen(!isOpen)}
                style={{width: props.width}}
                ref={menuRef}
            >
                {props.activeOption.name}
            </button>
            <nav
                className={`menu ${isOpen ? "active" : ""}`}
                style={{width: props.width}}
            >
                <ul className="menu__list">
                    {props.data.map((option, idx) => {
                        return (
                            <li
                                key={idx}
                                className="menu__item"
                                onClick={() => {props.setActiveOption(option), setOpen(false)}}
                            >
                                {option.name}
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </div>
    );
};

export default DropDownPurchase;