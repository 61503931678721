
const PrivacyPage = () => {
    return (
        <div style={{marginTop: "90px", display: "flex", flexDirection: "column", textAlign: "center"}} className="container">
            <h3 style={{margin: "auto", padding: "20px 0"}}>
                Какую информацию о вас мы собираем
            </h3>
            <div style={{marginBottom: "10px"}}>
                Типы данных, которые мы собираем и обрабатываем включают в себя следующее: -Ваш SteamID
                Дополнительные данные могут быть собраны, если вы решите поделиться ими, например, если вы заполните дополнительные поля в своем профиле.
            </div>
            <div style={{marginBottom: "10px"}}>
                Мы собираем некоторую или всю эту информацию в следующих случаях:
                - Вы авторизовываетесь как участник на этом сайте.
                - Вы используете нашу форму обратной связи.
                - Вы просматриваете этот сайт. Смотрите пункт "Политика в отношении файлов cookie" ниже.
            </div>
            <h3 style={{margin: "auto", padding: "20px 0"}}>
                Как используется ваша личная информация
            </h3>
            <div style={{marginBottom: "10px"}}>
                Мы можем использовать вашу личную информацию в следующих целях:
                - Для того, чтобы сделать вас зарегистрированным участником нашего сайта, чтобы мы могли осуществлять обслуживание
                - Другие варианты использования вашей личной информации
            </div>
            <div style={{marginBottom: "10px"}}>
                В ходе вашего использования нашего сайта мы можем собирать информацию, не связанную с личными сведениями о вас. Эти данные могут содержать техническую информацию о браузере или типе устройства, которые вы используете. Эта информация будет использоваться исключительно для целей аналитики и отслеживания количества посетителей нашего сайта.
            </div>
            <h3 style={{margin: "auto", padding: "20px 0"}}>
                Обеспечение безопасности данных
            </h3>
            <div style={{marginBottom: "10px"}}>
                Мы стремимся обеспечить безопасность любой информации, которую вы нам предоставляете. Чтобы предотвратить несанкционированный доступ или раскрытие информации, мы добавили подходящие меры и процедуры для защиты и обеспечения безопасности информации, которую мы собираем.
            </div>
            <h3 style={{margin: "auto", padding: "20px 0"}}>
                Политика в отношении файлов cookie
            </h3>
            <div style={{marginBottom: "10px"}}>
                Cookie - это небольшие текстовые файлы, которые будут установлены нашим сайтом на ваш компьютер, они позволят нам предоставлять определенные функции на нашем сайте, такие как возможность входа в систему или запоминание определенных предпочтений.
            </div>
            <h3 style={{margin: "auto", padding: "20px 0"}}>
                Права
            </h3>
            <div style={{marginBottom: "10px"}}>
                Вы имеете право получить доступ к личным данным, которые у нас имеются о вас, или получить их копию. Для этого свяжитесь с нами. Если вы считаете, что информация, которую мы о вас имеем является неполной или неточной, то вы можете попросить нас дополнить или исправить эту информацию.
            </div>
            <div style={{marginBottom: "10px"}}>
                Вы также имеете право запросить удаление ваших личных данных. Пожалуйста, свяжитесь с нами, если вы хотите, чтобы мы удалили ваши личные данные.
            </div>
            <h3 style={{margin: "auto", padding: "20px 0"}}>
                Принятие политики
            </h3>
            <div style={{marginBottom: "10px"}}>
                Дальнейшее использование нашего сайта означает ваше полное согласие с этой политикой. Если вы не согласны с нашей политикой, то пожалуйста, не используйте данный сайт. При регистрации мы также запросим ваше явное согласие с политикой конфиденциальности.
            </div>
            <h3 style={{margin: "auto", padding: "20px 0"}}>
                Изменения в политике
            </h3>
            <div style={{margin: "10px 0 40px"}}>
                Мы можем вносить изменения в данную политику в любое время. Вам может быть предложено ознакомиться и повторно принять информацию в этой политике, если она изменится в будущем.
            </div>
        </div>
    );
};

export default PrivacyPage;