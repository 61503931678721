import {Link} from "react-router-dom";
import {PATHS} from "../../../../../../../routers/utils/Paths.links";
import {dataForProfile} from "../../../../../../ProfilePage/components/Sidebar/Sidebar";

const ContentProfile = ({menuRef, setActive}) => {

    return (
        <div className="content-profile">
            { dataForProfile.map((item, idx ) =>
                    <Link
                        key={idx}
                        ref={menuRef}
                        className="burger-content-btn"
                        to={PATHS.PROFILE + item.to}
                        onClick={() => setActive(false)}
                    >
                        { item.text }
                    </Link>

            )}
            <div className="static-burger-btn">
                <Link
                    className="burger-content-btn"
                    to={PATHS.SHOP}
                    onClick={() => setActive(false)}
                >
                    Магазин
                </Link>
            </div>
        </div>
    );
};

export default ContentProfile;