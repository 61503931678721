import {useState} from "react";
import DropDownPurchase from "../../../../shared/DropDowns/DropDownPurchase";
import "./AdminPanel.scss";
import {ADMIN} from "../../../../../enum";
import CreateServer from "./components/CreateServer/CreateServer";
import CreateCategory from "./components/CreateCategory/CreateCategory";
import CreateProduct from "./components/CreateProduct/CreateProduct";

export const dataAdminCreate = [
    {
        id: ADMIN.PRODUCT,
        name: "Товар"
    },
    {
        id: ADMIN.SERVER,
        name: "Сервер"
    },
    {
        id: ADMIN.CATEGORY,
        name: "Категория"
    }
]

const AdminPanel = () => {
    const [activeTypePurchase, setActiveTypePurchase] = useState(dataAdminCreate[0])

    const collection = new Map([
        [ADMIN.SERVER, <CreateServer />],
        [ADMIN.CATEGORY, <CreateCategory />],
        [ADMIN.PRODUCT, <CreateProduct />],
    ])

    return (
        <div>
            <DropDownPurchase
                data={dataAdminCreate}
                activeOption={activeTypePurchase}
                setActiveOption={setActiveTypePurchase}
                width={"205px"}
            />
            <div className="create-block">
                { collection.get(activeTypePurchase.id) }
            </div>
        </div>
    );
};

export default AdminPanel;