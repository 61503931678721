import { create } from 'zustand'
import axios from "axios";
import {PATHS_API} from "../../../enum";

type Category = {
    active: boolean,
    main: boolean,
    name: string,
    _id: string
}

type Store = {
    categories: Category[]
    fetchCategories: any
}

export const useCategoriesStore = create<Store>((set) => ({
    categories: [],
    isLoading: false,
    errors: [],
    fetchCategories: async () => {
        const result = await axios.get( PATHS_API.CATEGORIES, {
            withCredentials: true
        })
        set({categories: result.data})
    }
}))