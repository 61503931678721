import './BurgerButton.scss';
import {useContext, useEffect, useRef, useState} from "react";
import {enumTheme, PATHS} from "../../../../../../../enum";
import {useLocation} from "react-router-dom";
import ContentShop from "./ContentShop/ContentShop";
import ContentProfile from "./ContentProfile/ContentProfile";
import {useClickOutside} from "../../../../../../hooks/useClickOutside";
import ContentDeposit from "./ContentDeposit/ContentDeposit";
import {ThemeContext} from "../../../../../../providers/ThemeProvider";
const BurgerButton = () => {
    const [active, setActive] = useState(false)
    const location = useLocation();

    const menuRef = useRef(null);

    // useClickOutside(menuRef, (event) => {
    //     // Проверяем, что event.target существует и что он относится к DOM-элементу
    //     if (event.target && menuRef.current && menuRef.current.contains(event.target)) {
    //         // Если клик на ссылке внутри меню, ничего не делаем
    //         if (event.target.tagName === "A") {
    //             return;
    //         }
    //     }
    //     setActive(false);
    // });

    // Функция для закрытия меню при клике вне компонента
    const closeMenuIfClickedOutside = (event) => {
        // Проверяем, что клик был не на элементе <Link> и не внутри меню
        if (!menuRef.current.contains(event.target) && event.target.tagName !== 'A') {
            setActive(false);
        }
    };

    // Добавляем слушатель события для закрытия меню
    useEffect(() => {
        document.addEventListener('click', closeMenuIfClickedOutside);
        return () => {
            document.removeEventListener('click', closeMenuIfClickedOutside);
        };
    }, []);

    // Обработчик клика на бургер-кнопку
    const handleBurgerClick = (event) => {
        event.stopPropagation(); // Останавливаем всплытие события, чтобы клик не попадал на родительский элемент
        setActive(!active);
    };


    return (
        <>
            <div ref={menuRef} onClick={handleBurgerClick} className="burger-btn">
                <svg width="26" height="26" viewBox="0 0 24 24" fill={"var(--main-text-color)"}>
                    <path
                        d="M22 3H1a1 1 0 000 2h21a1 1 0 100-2zm0 8H1a1 1 0 100 2h21a1 1 0 100-2zm-10 8H1a1 1 0 100 2h11a1 1 0 100-2z"
                        fill={"var(--main-text-color)"}></path>
                </svg>
            </div>
            <div className={`burger-content-wrapper ${active && "opacity-burger"}`}>
                { location.pathname === PATHS.SHOP && <ContentShop setActive={setActive} /> }
                { location.pathname === PATHS.DEPOSIT && <ContentDeposit setActive={setActive} /> }
                { location.pathname.startsWith(PATHS.PROFILE) && <ContentProfile setActive={setActive} /> }

            </div>
        </>
    );
};

export default BurgerButton;