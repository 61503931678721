import {Link} from "react-router-dom";
import {PATHS, PATHS_API} from "../../../../../../../../enum";
import ThemeSwitcher from "../../../../ThemeSwitcher/ThemeSwitcher";

const ContentShop = ({setActive}) => {
    const token = localStorage.getItem('isAuth') || null;

    return (
        <div className="content-shop">
            <Link
                className="burger-content-btn"
                to={PATHS.PROFILE + "/basket"}
                onClick={() => setActive(false)}
            >
                Профиль
            </Link>
            {
                !token && <a style={{"textDecoration" : "none", margin: "20px 0"}} className='btn' href={PATHS_API.LOGIN}>
                    Вход
                </a>
            }

            <ThemeSwitcher />
        </div>
    );
};

export default ContentShop;