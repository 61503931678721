import React from 'react';
import "./DropDown.scss";

const DropDown = () => {
    return (
        <select className="DropDownLang" name="ffff" id="11" >
            <option value="ru">Русский</option>
            <option value="ru">English</option>
        </select>
    );
};

export default DropDown;