import "./DepositWallet.scss"
import {PATHS} from "../../../routers/utils/Paths.links";
import {Link} from "react-router-dom";
import {IUser} from "../../../pages/ShopPage/components/Navbar/interface";

const DepositWallet = (props: IUser) => {
    const { user } = props
    return (
        <Link className="link-wallet" to={PATHS.DEPOSIT}>
            <div className="wallet-block">
                <p>
                    { user.balance?.toFixed(2) } ₽
                </p>
                <span>
                    <svg width="14" height="14" viewBox="0 0 18 15"
                         fill="var(--white-color)"
                    >
                        <path d="M14.625 12.143c-1.86 0-3.375-1.442-3.375-3.214 0-1.773 1.514-3.215 3.375-3.215H16.5V4.286c0-.788-.673-1.429-1.5-1.429h-.75V1.43c0-.79-.673-1.43-1.5-1.43H2.25C1.138 0 .218.774.038 1.785c-.016.059-.038.116-.038.18v10.892C0 14.04 1.01 15 2.25 15H15c.827 0 1.5-.64 1.5-1.429v-1.428h-1.875zM1.5 2.143c0-.394.336-.714.75-.714h10.5v1.428H2.25c-.414 0-.75-.32-.75-.714z"></path><path d="M17.438 6.786h-2.813c-1.24 0-2.25.961-2.25 2.143 0 1.181 1.01 2.142 2.25 2.142h2.813a.55.55 0 00.562-.535V7.32a.55.55 0 00-.563-.535zm-2.813 2.857c-.414 0-.75-.32-.75-.714 0-.395.336-.715.75-.715s.75.32.75.715c0 .394-.336.714-.75.714z"></path>
                    </svg>
                </span>
            </div>
        </Link>

    );
};

export default DepositWallet;