import { Routes } from "./index";
import PrivateRoute from "./PrivateRoute";
import {PATHS} from "./utils/Paths.links";
import {Switch, Redirect} from "react-router-dom";
import {useEffect, useState} from "react";
import {useUserStore} from "../../modules/shop/user/store";

const AppRouter = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const user = useUserStore(state => state.user)
    const token = localStorage.getItem('isAuth') || null;

    useEffect(() => {
        if(user.steamId || token) {
            localStorage.setItem('isAuth', 'true')
            setIsAuthenticated(true)
        }
    }, [user]);

    return (
        <Switch>
            { Routes.map((route) =>
                <PrivateRoute
                    {...route}
                    token={token}
                    key={route.path}
                 />
            )}
            <Redirect to={PATHS.SHOP} />
        </Switch>
    )
};

export default AppRouter;
