import {IRoute} from "../index";
import {PATHS} from "../utils/Paths.links";
import {Redirect, Route, RouteProps} from "react-router-dom";

interface IPrivateRoute extends RouteProps {
    withoutAuth?: boolean,
    childRoutes?: IRoute[],
    token?: string | null
}

const PrivateRoute = (props: IPrivateRoute) => {
    const { token, withoutAuth, childRoutes, ...route } = props

    if (withoutAuth) {
        return <Route {...route} />
    }

    return !!token
        ? <Route {...route} />
        : <Redirect to={ PATHS.SHOP } />
};

export default PrivateRoute;
