import "./Products.scss";
import {IDataProduct} from "./interfaces";
import {useServersStore} from "../../../../../modules/shop/servers/store";
import {useEffect, useState} from "react";
import Product from "./components/Product/Product";

const Products = ({dataProducts}: IDataProduct[]) => {
    const servers = useServersStore(state=> state.servers)
    const fetchServers = useServersStore(state=> state.fetchServers)

    useEffect(() => {
        fetchServers()
    }, [])

    return (
        <div className="products">
            { dataProducts.map((product, idx) => {
                return (
                    <Product key={idx} data={product} servers={servers} />
                )})
            }
        </div>
    );
};

export default Products;