import "./Footer.scss";
import DropDown from "../../../../shared/DropDowns/DropDown";
import {Link} from "react-router-dom";
import {PATHS} from "../../../../routers/utils/Paths.links";
import SocialIcons from "./components/SocialIcons/SocialIcons";

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className="footer-wrapper">
            <div className="d-flex just-content-center">
                <Link style={{paddingRight: "50px"}} className="policy" to={PATHS.AGREEMENT}>
                    Пользовательское соглашение
                </Link>
                <Link className="policy" to={PATHS.PRIVACY}>
                    Политика конфиденциальности
                </Link>
            </div>
            <footer>
                <div className="container-footer">
                    <div className="copyright"><strong>© ALCATRAZ </strong> 2021 - {currentYear}.</div>
                    <div className="social-links">
                        <a className="social-link" target="_blank" href="https://discord.gg/m3FJEg3cq7">Discord</a>
                        <a className="social-link" target="_blank" href="https://t.me/alcatrazdayz">Telegram</a>
                        <a className="social-link" target="_blank" href="https://vk.com/dayz_alcatraz">Vkontakte</a>
                    </div>
                    <SocialIcons />
                    <div className="made-in">
                        Сделано с 💙 в Зеленогорске
                    </div>
                    <DropDown />
                </div>
            </footer>
        </div>

    );
};

export default Footer;