import { FC } from "react";

export interface AlertProps {
    text: string;
}

const AlertSecondary: FC<AlertProps> = ({ text }) => {
    return (
        <div className="wrapper-alert-shared wrapper-alert-secondary">
            <div className="alert-info-shared">
                { text }
            </div>
        </div>
    );
};

export default AlertSecondary;