import InputDefault from "../../../../shared/Inputs/InputDefault";
import {useEffect, useState} from "react";
import axios from "axios";
import {diffDays} from "../../../../shared/functions/diffDays";

interface ICreator {
    codeCreator: string
    percentForReferral: number
    percentForCreator: number
    steamId: string
    cardWithdraw: string
    amountWithdraw: number
    dateStart: Date
    dateEnd: Date
}

const Referral = () => {
    const [loading, setLoading] = useState(true);
    const [clickReferral, setClickReferral ] = useState(false)
    const [referralCode, setReferralCode] = useState("")
    const [cardWithdraw, setCardWithdraw] = useState("")
    const [daysForEndReferral, setDaysForEndReferral] = useState(29)

    const [dataCreator, setDataCreator] = useState<ICreator>()
    const [formattedCard, setFormattedCard] = useState('');

    const handleClick = () => {
        if (referralCode === "") return;

        axios.post(`http://localhost:5000/referral`, {
            codeCreator: referralCode,
        }, {
            withCredentials: true,
        })
            .then(() => {
                setClickReferral(false);
                setReferralCode("");
            })
            .catch(error => {
                console.error("Ошибка:", error);
                setClickReferral(false);
                setReferralCode("");
            });
    };

    useEffect(() => {
        axios.get(`http://localhost:5000/api/referral/creator`,{
            withCredentials: true
        })
        .then(({data}) => {
            setDataCreator(data)
            const formatted = data?.cardWithdraw?.replace(/(.{4})/g, '$1 ')
            setFormattedCard(formatted)
            setDaysForEndReferral(diffDays(data.dateEnd))
        })
    }, [])

    return (
        <>
            { dataCreator?.percentForCreator ? //Если человек является стримером(creator)
                <>
                    <div className="wrapper-input-transfer">
                        { dataCreator.cardWithdraw
                            ? // Если в базе уже есть номер карты для вывода
                            <div style={{width: "100%"}}>
                                <div className="card-withdraw">{ formattedCard }</div>
                                <div className="wrapper-button-transfer">
                                    <button className="button-buy">Сменить</button>
                                </div>
                            </div>

                            : // Если в базе ещё нету карты для вывода
                            <>
                                <InputDefault value={cardWithdraw} setValue={setCardWithdraw} placeholder={"Адрес кошелька USDT TRC-20 или банковская карта"} />
                                <div className="wrapper-button-transfer">
                                    <button className="button-buy">Добавить для выплат</button>
                                </div>
                            </>
                        }
                        {/*<div className="clue-transfer">Как происходят выплаты?</div>*/}
                    </div>
                    <div className="no-payments">
                        <h3>
                            Вы являетесь нашим партнёром, спасибо - {dataCreator.codeCreator} <span>🤝</span>
                        </h3>
                        <p>
                            Каждые {dataCreator.percentForCreator}% пополнений ваших рефералов, поступают на баланс ниже
                        </p>
                        <div className="referral-bonus-info">
                            Ваш баланс на вывод - {dataCreator.amountWithdraw} RUB
                        </div>
                    </div>
                </>
                :
                <>
                    { dataCreator?.codeCreator //Если человек не является creator, а просто реферал активный
                        ?
                        <div className="no-payments">
                            <h3>
                                Ближайшее время вы реферал - {dataCreator.codeCreator} <span>🤝</span>
                            </h3>
                            <p>
                                Через {daysForEndReferral} дней нужно будет ввести код автора повторно!
                            </p>
                            <div className="referral-bonus-info">
                                Ваш бонус +{dataCreator.percentForReferral}% к пополнению баланса
                            </div>
                        </div>

                        : //Если человек не является creator, и не является рефералом
                        <>
                            <div className="wrapper-input-transfer">
                                <InputDefault value={referralCode} setValue={setReferralCode} placeholder={"referral code"} />
                                {/*<div className="clue-transfer">Как стать партнёром проекта?</div>*/}
                            </div>
                            <div className="wrapper-button-transfer">
                                <button onClick={handleClick} className="button-buy">Применить</button>
                            </div>
                            <div className="no-payments">
                                <h3>
                                    Вы пока не участвуете в реферальной программе <span>😕</span>
                                </h3>
                                <p>
                                    Чтобы участвовать вам нужен код
                                </p>
                            </div>
                        </>
                    }
                </>
            }
        </>
    );
};

export default Referral;