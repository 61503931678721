import InputDefault from "../../../../../../../../shared/Inputs/InputDefault";
import {useServersStore} from "../../../../../../../../../modules/shop/servers/store";
import {useCategoriesStore} from "../../../../../../../../../modules/shop/categories/store";
import {useEffect, useState} from "react";
import axios from "axios";
import {PATHS_API} from "../../../../../../../../../enum";

const data = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert', 'Louisa', 'Lester', 'Lola', 'Lydia', 'Hal', 'Hannah', 'Harriet', 'Hattie', 'Hazel', 'Hilda'].map(item => ({ label: item, value: item }));
const dataTypeProduct = [{label: "Продукт", value: "product"}, {label: "Приоритет вход", value: "priority"}]

const Form = ({ activeProductAction }) => {
    const [productAction, setProductAction] = useState()
    const [formDataProduct, setFormDataProduct] = useState({
        name: "",
        description: "",
        categories: [],
        servers: [],
        className: "",
        type: "",
        count: "",
        imageUrl: "",
        price: undefined,
        personal: false,
    });

    const servers = useServersStore(state=> state.servers)
    const fetchServers = useServersStore(state=> state.fetchServers)

    const categories = useCategoriesStore(state => state.categories)
    const fetchCategories = useCategoriesStore(state => state.fetchCategories)

    useEffect(() => {
        fetchServers()
        fetchCategories()
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormDataProduct({ ...formDataProduct, [name]: value });
    };

    const handleChangeNumber = (e) => {
        const { name, value } = e.target;
        setFormDataProduct({ ...formDataProduct, [name]: parseInt(value) });
    };

    const handleChangeSelect = (e) => {
        const { name, selectedOptions } = e.target;
        const values = Array.from(selectedOptions, option => option.value);
        setFormDataProduct({ ...formDataProduct, [name]: values });
    };

    const handleSubmit = async () => {
        if (formDataProduct.name.trim() === "") {
            console.error("Имя не может быть пустым");
            return;
        }

        console.log(formDataProduct)

        try {
            if (activeProductAction.id === 1) {
                const response = await axios.post(PATHS_API.PRODUCTS, formDataProduct, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                });
                if (response.status === 201) {
                    console.log('Продукт успешно создан!');
                    setFormDataProduct({
                        name: "",
                        description: "",
                        categories: [],
                        servers: [],
                        className: "",
                        type: "",
                        count: "",
                        imageUrl: "",
                        price: undefined,
                        personal: false,
                    }); // Очистка форм
                } else {
                    console.error('Ошибка при отправке данных:', response.statusText);
                }
            } else {
                const response = await axios.put(PATHS_API.PRODUCTS + `/${productAction}`, formDataProduct, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                });
                if (response.status === 201) {
                    console.log('Продукт успешно создан!');
                    setFormDataProduct({
                        name: "",
                        description: "",
                        categories: [],
                        servers: [],
                        className: "",
                        type: "",
                        count: "",
                        imageUrl: "",
                        price: undefined,
                        personal: false,
                    }); // Очистка форм
                } else {
                    console.error('Ошибка при отправке данных:', response.statusText);
                }
            }


        } catch (error) {
            console.error("Ошибка:", error.response ? error.response.data : error.message);
        }
    };

    return (
        <form onSubmit={async (e) => {
            e.preventDefault();
            await handleSubmit();
        }}>
            <div className="d-flex flex-direction-column gap12">

                {
                    activeProductAction.id === 2 && <InputDefault value={productAction} setValue={setProductAction} placeholder={"ID продукта"} />
                }
                <InputDefault onChange={handleChange} name={"name"} value={formDataProduct.name} placeholder={"Название продукта"} />
                <InputDefault onChange={handleChange} name={"description"} value={formDataProduct.description} placeholder={"Описание"} />
                <InputDefault onChange={handleChange} name={"className"} value={formDataProduct.className} placeholder={"СlassName"} />
                <InputDefault onChange={handleChangeNumber} name={"count"} value={formDataProduct.count} placeholder={"Кол-во стаков"} />
                <InputDefault onChange={handleChangeNumber} name={"price"} value={formDataProduct.price} placeholder={"Цена"} />
                <InputDefault onChange={handleChange} name={"imageUrl"} value={formDataProduct.imageUrl} placeholder={"Ссылка на картинку"} />
                <select className="admin-select" value={formDataProduct.type} onChange={handleChange} name="type">
                    <option value="product">Товар</option>
                    <option value="priority">Приоритет вход</option>
                </select>
                <select multiple className="admin-select" value={formDataProduct.servers} onChange={handleChangeSelect} name="servers">
                    { servers.map((server) => {
                        return(
                            <option value={server._id}>{ server.name }</option>
                        )
                    })}
                </select>
                <select multiple className="admin-select" value={formDataProduct.categories} onChange={handleChangeSelect} name="categories">
                    { categories.map((category) => {
                        return(
                            <option value={category._id}>{ category.name }</option>
                        )
                    })}
                </select>
                <div className="d-flex just-content-center">
                    <input type="checkbox"/>
                    <div>Персональный товар</div>
                </div>
                <button type="submit" className="button-buy">Создать</button>
            </div>
        </form>
    );
};

export default Form;