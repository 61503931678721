
const NoItems = () => {
    return (
        <div className="no-payments">
            <h3>
                Вы пока не отправляли/получали перевод <span>🤔</span>
            </h3>
            <p>
                Подробнее в подсказке выше!
            </p>
        </div>
    );
};

export default NoItems;