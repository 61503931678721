import "./Alert.scss";
import {FC} from "react";
import {AlertProps} from "../AlertSecondary/AlertSecondary";

const Alert: FC<AlertProps> = ({ text}) => {
    return (
        <div className="wrapper-alert-shared">
            <div className="alert-info-shared">
                { text }
            </div>
        </div>
    );
};

export default Alert;