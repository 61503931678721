
enum PaymentStatus {
    PENDING = "pending" || "PENDING",
    SUCCESS = "success" || "SUCCESS",
    FAILED = "failed" || "FAILED",
    FAIL = "FAIL"
}

const Status = ({payment}) => {
    const getStatusMessage = (status) => {
        switch (status) {
            case "pending" || "PENDING" :
                return "Ожидание";
            case "success" || "SUCCESS" :
                return "Успешно";
            case "failed" || "FAILED" || "FAIL" :
                return "Ошибка";
            case "FAIL" :
                return "Ошибка";
            default:
                return "Неизвестный статус";
        }
    };

    const getType = (status) => {
        if (status === PaymentStatus.PENDING) {
            return "pending"
        }  else if(status === PaymentStatus.SUCCESS)  {
            return "success"
        } else if(status === PaymentStatus.FAILED)  {
            return "failed"
        } else if(status === PaymentStatus.FAIL)  {
            return "failed"
        }
    }

    return (
        <div className={`status ${getType(payment)}`}>
            { getStatusMessage(payment) }
        </div>
    );
};

export default Status;