
const NoItems = ({purchasesServices, purchasesProducts}) => {
    return (
        !purchasesServices.length && !purchasesProducts.length &&
        <div style={{width: "100%"}} className="d-flex just-content-center">
            <div className="no-payments">
                <h3>
                    Ваша корзина на данный момент пустая <span>🤔</span>
                </h3>
                <p>
                    Исправить это можно, на главной странице
                </p>
            </div>
        </div>
    );
};

export default NoItems;