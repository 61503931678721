import {SERVER_NAME} from "../../../../../enum";

export const filterServerResult = (activeServerHeader, dataProducts) => {
    return dataProducts.filter((product) => {
        if (activeServerHeader === SERVER_NAME.ALL) {
            return true;
        } else {
            const filterProducts: string[] = [];
            product.servers?.map((server) => filterProducts.push(server))
            return !filterProducts.includes(activeServerHeader);
        }
    })
}