import spbimg from "../../../../assets/spb.svg";
import cardimg from "../../../../assets/visa-ms.svg";
import cardimg2 from "../../../../assets/paypalych-logo.svg";
import cardimg3 from "../../../../assets/SberPay.png";
import cardimg4 from "../../../../assets/usdt.svg";
import "./DefaultMethods.scss";
import PopupNotFullyIntegration from "./PopupNotFullyIntegration/PopupNotFullyIntegration";
import {useEffect, useState} from "react";
import axios from "axios";
import {PATHS_API} from "../../../../../enum";

const defaultMethods: IDefaultMethod[] = [
    {
        paymentSystem: "Antilopay",
        name: "QR",
        createUrl: "/api/payment-method/create-antilopay",
        preferMethod: "SBP",
        min: 100,
        currency: "RUB",
        img: spbimg
    },
    {
        paymentSystem: "Antilopay",
        name: "CARD RU",
        createUrl: "/api/payment-method/create-antilopay",
        preferMethod: "CARD_RU",
        img: cardimg2,
        min: 100,
        currency: "RUB",
    },
    {
        paymentSystem: "BuyMeACoffee",
        name: "CARD EU",
        createUrl: "none",
        preferMethod: "",
        img: cardimg,
        min: 100,
        currency: "RUB",
    },
    {
        paymentSystem: "Crypto",
        name: "Crypto",
        createUrl: "/api/payment-method/create-betatransfer",
        preferMethod: "Crypto",
        img: cardimg4,
        min: 500,
        currency: "RUB",
    },
    {
        paymentSystem: "P2P Ukraine",
        name: "P2P UAH",
        createUrl: "/api/payment-method/create-betatransfer",
        preferMethod: "Card5",
        img: cardimg,
        min: 650,
        currency: "RUB",
    }
    // {
    //     paymentSystem: "Antilopay",
    //     name: "SberPay",
    //     createUrl: "/api/payment-method/create-antilopay",
    //     preferMethod: "SBER_PAY",
    //     img: cardimg3,
    //     min: 100,
    //     currency: "RUB",
    // },
    // {
    //     paymentSystem: "Paypalych",
    //     name: "CARD KZ",
    //     createUrl: "/api/payment-method/create-paypalych",
    //     preferMethod: "any",
    //     img: cardimg,
    //     min: 250,
    //     currency: "RUB",
    // },
    // {
    //     paymentSystem: "YooMoney",
    //     name: "YooMoney",
    //     createUrl: "/api/payment-method/create-betatransfer",
    //     preferMethod: "YooMoney",
    //     img: cardimg,
    //     min: 1000,
    //     currency: "RUB",
    // },
    // {
    //     paymentSystem: "Card Ukraine",
    //     name: "CARD UAH",
    //     createUrl: "/api/payment-method/create-betatransfer",
    //     preferMethod: "Card10",
    //     img: cardimg,
    //     min: 500,
    //     currency: "UA",
    // },
    //  {
    //      paymentSystem: "Cryptocloud",
    //      name: "Crypto",
    //      createUrl: "/api/payment-method/create-cryptocloud",
    //      preferMethod: "any",
    //      img: cardimg4,
    //      min: 100,
    //      currency: "RUB",
    // },
    // {
    //     paymentSystem: "Paypalych",
    //     name: "TRC-20",
    //     createUrl: "/api/payment-method/create-paypalych",
    //     preferMethod: "any",
    //     img: cardimg4,
    //     min: 150,
    //     currency: "RUB",
    // },
]

export interface IDefaultMethod {
    name: string,
    img: any,
    createUrl: string,
    preferMethod: string,
    paymentSystem: string,
    min: number,
    currency: string,
}

const DefaultMethods = ({setMethodPayment, setActivePopup, setActivePopupNotFully}) => {
    const [userLocation, setUserLocation] = useState(null)
    const activeMethod = (method) => {
        setActivePopup(true)
        setMethodPayment(method)
    }


    useEffect(() => {
        axios.get( PATHS_API.LOCATION, {
            withCredentials: true,
        })
            .then(({data}) => {
                setUserLocation(data.country);
            })
            .catch((error) => {
                console.error("Ошибка при загрузке", error);
            })
    },[])

    const filteredMethods = defaultMethods.filter((method) => {
        // Убираем объекты "BuyMeACoffee" и "P2P Ukraine", если локация "RU" или "BY"
        if (userLocation === "RU" || userLocation === "BY" || userLocation == null) {
            return method.paymentSystem !== "BuyMeACoffee" && method.paymentSystem !== "P2P Ukraine";
        }
        return true; // Остальные объекты остаются
    });

    return (
        <div style={{marginBottom: "20px"}}>
            <h4 className="title-method">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="var(--deposit-title-method)">
                    <path
                        d="M19 3H5a5.006 5.006 0 00-5 5h24a5.006 5.006 0 00-5-5zM0 16a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5v-6H0v6zm7-.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                        ></path>
                </svg>
                <p>Карты и крипта</p>
            </h4>
            <div style={{marginBottom: "20px"}} className="methods">
                {
                    filteredMethods.map((method,idx) => {
                        return (
                            <div key={idx} onClick={() => activeMethod(method)} className="default-method-block d-flex">
                                <div className="default-method-name d-flex just-content-space-between">
                                    <p>{ method.name }</p>
                                </div>
                                <img src={ method.img } alt={ method.name } />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default DefaultMethods;