import "./PersonalProducts.scss";
import {useEffect, useState} from "react";
import axios from "axios";
import {IOption} from "../../../../shared/DropDowns/DropDownPurchase/DropDownPurchase";
import {useUserStore} from "../../../../../modules/shop/user/store";
import {useServersStore} from "../../../../../modules/shop/servers/store";
import Loader from 'rsuite/Loader';
import 'rsuite/Loader/styles/index.css';
import PassProduct from "./components/PassProduct/PassProduct";
import NoProducts from "./components/NoProducts/NoProducts";
import Product from "../../../ShopPage/components/Products/components/Product/Product";
import {PATHS_API} from "../../../../../enum";


const PersonalProducts = () => {
    const [loading, setLoading] = useState(true);
    const [activeProduct, setActiveProduct] = useState<IOption>({ _id: 0, name: "Выберите товар" });
    const [personalProducts, setPersonalProducts] = useState<IOption[]>([]);
    const [inputValue, setInputValue] = useState("");
    const [creatorProducts, setCreatorProducts] = useState([])
    const user = useUserStore(state => state.user)
    const servers = useServersStore(state => state.servers)
    const fetchServers = useServersStore(state => state.fetchServers)

    useEffect(() => {
        fetchServers()
    }, [])

    // Функция для получения личных товаров
    const fetchPersonalProducts = async () => {
        setLoading(true)
        await axios.get(`${PATHS_API.PERSONAL}`, {
            withCredentials: true,
        })
            .then(({data}) => {
                setPersonalProducts(data);
                //Записать продукты только те где creator это данный пользователь
                const creatorProductsResult = data.filter((product) => product.creator === user?.steamId)
                creatorProductsResult && setCreatorProducts(creatorProductsResult)

                //Перезаписать актуальный товар
                const currentProduct = data.find((product) => product._id === activeProduct._id)
                currentProduct && setActiveProduct(currentProduct)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                console.error("Ошибка при загрузке личных товаров:", error);
            })
    };

    useEffect(() => {
        if (user?.steamId) {
            fetchPersonalProducts();
        }
    }, [user]);

    // Функция для обновления информации о товаре
    const updateProductOwners = async () => {
        if (activeProduct._id && inputValue) {
            await axios.put(`http://localhost:5000/products/${activeProduct._id}`, {
                owners: [...activeProduct.owners, inputValue],
            }, {
                withCredentials: true,
            })
                .then(() => {
                    setInputValue("");
                    fetchPersonalProducts();
                })
                .catch((error) => {
                    console.error("Ошибка при обновлении владельца товара:", error);
                })
        }
    };

    return (
        <div className="personal-products just-content-space-between">
            {/*<div className="clue">Что такое персональный товар?</div>*/}
            { loading
                ? <Loader size="md" backdrop />
                : personalProducts.length
                    ? <>
                        <div className="wrapper-personal-products">
                            {personalProducts.map((product, idx) => (
                                <Product key={idx} data={product} servers={servers} personal />
                            ))}
                        </div>
                        <PassProduct
                            activeProduct={activeProduct}
                            setActiveProduct={setActiveProduct}
                            updateProductOwners={updateProductOwners}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            creatorProducts={creatorProducts}
                        />
                    </>
                    : <NoProducts />
            }
        </div>
    );
};

export default PersonalProducts;