
const CardProduct = ({ data }) => {
    return (
        <div className="card-v2 d-flex">
            <div className="default-method-name card-v2-title d-flex ai-flex-end just-content-center">
                <div className="info-product">
                    <p>{ data.name }</p>
                    <span className="v2-price"> { data.price } ₽</span>
                </div>
            </div>
            <img src={ data.imageUrl } alt={data.name} />
        </div>
    );
};

export default CardProduct;