import {useState} from "react";
import axios from "axios";
import InputDefault from "../../../../../../shared/Inputs/InputDefault";
import {PATHS_API} from "../../../../../../../enum";

const CreateCategory = () => {
    const [categoryName, setCategoryName] = useState("");

    const handleClick = () => {
        if (categoryName === "") return;

        axios.post(PATHS_API.CATEGORIES, {
            name: categoryName,
        }, {
            withCredentials: true,
        })
            .then(() => {
                setCategoryName("");
            })
            .catch(error => {
                console.error("Ошибка:", error);
                setCategoryName("");
            });
    };

    return (
        <>
            <h4>Создание категории</h4>
            <div className="admin-wrapper d-flex gap12">
                <div className="admin-wrapper-block">
                    <InputDefault value={categoryName} setValue={setCategoryName} placeholder={"Название категории"} />
                    <button onClick={handleClick} className="button-buy">Создать</button>
                </div>
            </div>
        </>
    );
};

export default CreateCategory;