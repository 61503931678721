import React from 'react';
import "./InputAmount.scss";

interface IInput {
    data: any,
    value: number,
    setValue: (string) => void,
    placeholder?: string,
    disablePrompt?: boolean
}

const sum = [0, 1000, 2000, 3000]
const percent = [0, 0.1, 0.15, 0.3]
const personalBonus = 0.01


const InputAmount = (props: IInput) => {
    const {value, setValue, placeholder, disablePrompt = false} = props
    let actualBonus = 0

    sum.forEach(function(item, i, arr) {
        if (value >= item) {
            actualBonus = percent[i];
        }
    });


    return (
        <div className="d-flex">
            <div className="input-amount-wrapper">
                <input
                    placeholder={placeholder}
                    className="input input-amount"
                    value={value}
                    onChange={(e) => {setValue(e.target.value)}}
                    type="number"
                />
                {
                    !disablePrompt && <div className="prompt-btn-info">
                        {(value * (actualBonus + personalBonus) + (+value)).toFixed(2)}
                    </div>
                }
            </div>
            <div className="amount-currency">
                RUB
            </div>
        </div>

    );
};

export default InputAmount;