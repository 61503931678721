
const AgreementPage = () => {
    return (
        <div style={{marginTop: "90px", display: "flex", flexDirection: "column", textAlign: "center"}} className="container">
            <h3 style={{margin: "auto", padding: "20px 0"}}>ОБЩИЕ ПОЛОЖЕНИЯ</h3>
                <div style={{marginBottom: "10px"}}>
                        Настоящий документ (далее – Правила, Соглашение) является официальной публичной офертой (далее – «Исполнитель», «Оферент»), адресованное неопределенному кругу лиц и содержит все существенные условия для предоставления услуг и купли-продажи товаров, предлагаемых Оферентом и представленных на сайте https://alcatraz-dayz.com.
                </div>
                 <div style={{marginBottom: "10px"}}>
                         В соответствии с п. 2 ст. 437 Гражданского кодекса Российской Федерации (далее – ГК РФ) в случае принятия изложенных ниже условий и оплаты услуг, физическое лицо, производящее акцепт этой оферты, в срок установленный для ее акцепта, становится Заказчиком (Акцептантом, Пользователем) (в соответствии с п. 3 ст. 438 ГК РФ Акцепт Оферты равносилен заключению договора на условиях, изложенных в оферте), а Исполнитель (Оферент) и Заказчик (Акцептант) совместно — Сторонами договора-оферты.<br/>
                </div>
                <div style={{marginBottom: "10px"}}>
                        В связи с вышеизложенным, настоящий Договор заключается в особом порядке на основании статьи 434 ГК РФ, путем Акцепта Оферты, содержащей все существенные условия Договора, без дополнительного подписания Сторонами бумажной версии Договора. Настоящий Договор имеет юридическую силу и является равносильным договору, подписанному Сторонами.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Стороны признают согласованным, что безусловным Акцептом данной Оферты является факт оплаты Заказчиком услуг, предложенных Исполнителем. Перечень и содержание товаров и услуг, которые Заказчик приобретает у Исполнителя, формируется на сайте https://alcatraz-dayz.com
                </div>
                <div style={{marginBottom: "10px"}}>
                        Лица младше 18 лет не имеют права принимать условия настоящего Соглашения.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Стороны признают согласованным, что акты приема передачи товаров и услуг сторонами не составляется.
                </div>
                <div style={{marginBottom: "10px"}}>
                        В случае несогласия с настоящими Правилами Пользователь обязуется не принимать условия настоящей оферты и/или отказаться от выполнения Соглашения.
                </div>
                <h3 style={{margin: "auto", padding: "20px 0"}}>ПРЕДМЕТ СОГЛАШЕНИЯ</h3>

                <div style={{marginBottom: "10px"}}>
                        Исполнитель обязуется оказать Заказчику на возмездной основе услуги и/или товары, выбранные Заказчиком, в виде и на условиях, представленных на сайте Исполнителя https://dayz-store.com (далее – Сайт), а Заказчик обязуется принять эти услуги и/или товары и оплатить их в порядке, указанном на Сайте.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Права и обязанности по сделкам, совершенным Исполнителем, в соответствии с настоящим Договором, возникают непосредственно у Заказчика.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Настоящий Договор считается заключенным с даты акцепта настоящей Оферты
                </div>
                <h3 style={{margin: "auto", padding: "20px 0"}}>Права и обязанности Исполнителя</h3>


                <div style={{marginBottom: "10px"}}>
                        Исполнитель обязан:
                </div>

                <div style={{marginBottom: "10px"}}>
                        Оказать услуги и/или предоставить товары надлежащим способом и в согласованные Сторонами сроки.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Информировать Заказчика о ходе оказания услуг при поступлении от него соответствующих запросов.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Исполнитель имеет право:
                </div>
                <div style={{marginBottom: "10px"}}>
                        Получать своевременную и полную оплату товаров и/или услуг.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Получить от Заказчика полную и достоверную информацию, необходимую для оказания услуг.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Привлекать для оказания услуг третьих лиц — Соисполнителей.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Приостановить доступ Пользователя к товарам и/или услугам, если его действия или бездействия могут нанести вред Сайту или Исполнителю, либо нарушают иные правила, установленные Исполнителем.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Заказчик обязуется:
                </div>

                <div style={{marginBottom: "10px"}}>
                        Своевременно и в полном объеме оплачивать услуги Исполнителя.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Своевременно и в полном объеме предоставить Исполнителю информацию, необходимую для оказания услуг.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Содействовать всеми необходимыми способами для успешного оказания услуг.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Заказчик имеет право:
                </div>
                <div style={{marginBottom: "10px"}}>
                        Получить услуги, в порядке и на условиях, указанных в настоящем Договоре.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Запрашивать у Исполнителя информацию о ходе оказания услуг.
                </div>
                <h3 style={{margin: "auto", padding: "20px 0"}}>СРОК ДЕЙСТВИЯ ДОГОВОРА</h3>

                <div style={{marginBottom: "10px"}}>
                        Настоящий Договор считается заключенным с момента Акцепта Оферты (с даты оплаты Заказчиком товаров и/или услуг Исполнителя, если иное не установлено Приложениями к Договору) и действует до полного выполнения сторонами взятых на себя обязательств.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Исполнитель вправе в одностороннем (внесудебном) порядке отказаться от исполнения Договора путем направления Заказчику уведомления об отказе от исполнения Договора в случае непредоставления Заказчиком необходимых для оказания услуг документов или в иных случаях, указанных в Договоре.
                </div>
                <h3 style={{margin: "auto", padding: "20px 0"}}>ОТВЕТСТВЕННОСТЬ СТОРОН</h3>

                <div style={{marginBottom: "10px"}}>
                        Исполнитель не несет ответственности за просрочку исполнения (неисполнения) своих обязательств по настоящему Договору, если указанные обстоятельства вызваны действиями или бездействием Заказчика, или государственных органов, а также в случае изменения законодательства Российской Федерации, регулирующего вопросы государственной регистрации прав на недвижимое имущество.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Заказчик своей волей и в своем интересе, производя оплату стоимости товаров или услуг по настоящему Договору, действует добросовестно и находится в здравом уме, подтверждает свои намерения на такую оплату и на оказание услуг Исполнителем (исполнение Договора), утрачивает право возражать против произведения такой оплаты, а равно с ним утрачивает право на признание настоящего Договора недействительным и признание Договора незаключенным.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Заказчик понимает, что услуги по настоящему Договору оказываются лишь в тех объемах и теми способами, которые указаны в Договоре и на Сайте. Исполнитель не даёт Заказчику каких-либо гарантий в отношении услуг, если требования Заказчика отличаются от описания, представленных на Сайте.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Исполнитель не несет ответственность за действительность и законность получения предоставленных Заказчиком документов и информации.
                </div>
                <div style={{marginBottom: "10px"}}>
                        В случае расторжения (отказа от исполнения) настоящего Договора по вине/инициативе Заказчика, денежные средства, оплаченные по настоящему Договору, возврату не подлежат.
                </div>
                <div style={{marginBottom: "10px"}}>
                        В исключительных случая Исполнитель по своей инициативе может вернуть денежные средства в случае расторжения (отказа от исполнения) настоящего Договора по вине/инициативе Заказчика при условии обращения Заказчика в течение 72 часов с момента внесения оплаты по Договору. Возврат денежных средств возможен только если Пользователь не использовал или не активировал приобретенный товар.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Стороны освобождаются от ответственности за полное или частичное неисполнение обязательств по настоящему Договору в случае наступления обстоятельств непреодолимой силы, независимых от воли сторон, которых даже внимательная сторона не могла бы избежать и устранить их последствия (форс-мажор).
                </div>
                <h3 style={{margin: "auto", padding: "20px 0"}}>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>

                <div style={{marginBottom: "10px"}}>
                        Стороны признают обязательную силу за перепиской по адресам e-mail и мессенджерах, указанных каким-либо образом при переписке между Сторонами. Простые распечатки (скриншоты) с почтовых ящиков и мессенджеров подтверждают факт оказания услуг, выполнения работ, предоставления товаров, обмен документами, изменение ранее заключенного договора и другие юридически значимые действия.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Пользователь проинформирован, что товары, приобретенные в рамках настоящего Соглашения, возврату и обмену не подлежат.
                </div>
                <div style={{marginBottom: "10px"}}>
                        В случае споров по Договору установлен обязательный претензионный порядок, срок рассмотрения претензии - 15 рабочих дней. Споры рассматриваются в суде общей юрисдикции по адресу Исполнителя.
                </div>
                <div style={{marginBottom: "10px"}}>
                        Заказчик настоящим подтверждает, что до заключения (акцептования) он внимательно изучил настоящий Договор (условия Оферты), все термины и условия, использованные в Договоре, понятны. Заказчик в полном объеме подтверждает и принимает условия настоящего Договора.
                </div>
                <div style={{margin: "10px 0 40px"}}>
                        Акцептуя настоящий Договор, Заказчик даёт своё согласие Исполнителю на использование персональных данных, сроком на 10 (десять) лет: их обработку, хранение, систематизацию, накопление, использование, обновление, свободно своей волей и в своем интересе в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных», с целью оказания услуг, предусмотренных настоящим Договором, а также для информирования Заказчика об акциях и мероприятиях, проводимых Исполнителем.
                </div>
        </div>
    );
};

export default AgreementPage;