import "./ProgressLevel.scss";
import Progress from 'rsuite/Progress';
import 'rsuite/Progress/styles/index.css';
import {useEffect} from "react";
import {useUserStore} from "../../../../../../../modules/shop/user/store";

const ProgressLevel = ({user}) => {
    const amoun = user.currentAmount - (5000 * Math.floor(user.currentAmount / 5000))
    const gg = amoun / 5000 * 100

    return (
        <div className="progress-level">
            <div className="progress-line">
                <Progress.Line strokeColor={"#3579F6FF"}  percent={gg || 0} status="active" showInfo={false} />
            </div>
            <div className="wrapper-span-progress">
                <span className="">
                    { amoun || 0 } RUB
                </span>
                <span>
                    5000 RUB
                </span>
            </div>

        </div>
    );
};

export default ProgressLevel;