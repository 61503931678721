import "./Bonus.scss";

const Bonus = ({user}) => {
    return (
        <div className="bonus">
            +{user.personalBonus}%
        </div>
    );
};

export default Bonus;