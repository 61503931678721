import "./Payments.scss";
import {useEffect, useState} from "react";
import Loader from "rsuite/Loader";
import PaymentsList from "./components/Payments/PaymentsList";
import axios from "axios";
import {PATHS_API} from "../../../../../enum";
import {useTransfersStore} from "../../../../../modules/profile/transfers/store";
import {usePaymentsStore} from "../../../../../modules/profile/payments/store";

export const data2 = [
    {
        date: "26.08.2024",
        time: "18:58:35",
        id: "230",
        price: "190",
        bonus: "232",
        balance: "720",
        system: "PayPalych"
    },
    {
        date: "25.08.2024",
        time: "11:58:35",
        id: "165",
        price: "90",
        bonus: "232",
        balance: "2340",
        system: "PayPal"
    },
    {
        date: "25.08.2024",
        time: "10:58:35",
        id: "152",
        price: "1900",
        bonus: "2032",
        balance: "5020",
        system: "FreeKassa"
    },
    {
        date: "22.08.2024",
        time: "06:28:35",
        id: "92",
        price: "500",
        bonus: "500",
        balance: "720",
        system: "Boosty"
    },
    {
        date: "26.08.2024",
        time: "15:18:35",
        id: "27",
        price: "690",
        bonus: "690",
        balance: "1220",
        system: "Boosty"
    },
    {
        date: "26.08.2024",
        time: "18:58:35",
        id: "3",
        price: "100",
        bonus: "232",
        balance: "820",
        system: "BuyMeaCoffee"
    }
]

const Payments = () => {
    const [loading, setLoading] = useState(false);
    const payments = usePaymentsStore(state => state.payments)

    return (
         <div className="payments">
             { loading
                 ? <Loader size="md" backdrop />
                 : <PaymentsList resultPayments={payments} />
             }
         </div>
    );
};

export default Payments;