import './App.scss'
import {ThemeProvider} from "./providers/ThemeProvider";
import AppRouter from "./routers/AppRouter";
import Navbar from "./pages/ShopPage/components/Navbar/Navbar";
import ScrollToTop from "./shared/ScrollToTop/ScrollToTop";

function App() {
  return (
      <ThemeProvider>
          <ScrollToTop />
          <Navbar />
          <AppRouter />
      </ThemeProvider>
  )
}

export default App
