import {enumTheme} from "../../enum";
import {createContext, useEffect} from "react";
import {useLocalStorage} from "../hooks/useLocalStorage";
import {CustomProvider} from "rsuite";

export const ThemeContext = createContext();

export const ThemeProvider = ({children}) => {
    const [theme, setTheme] = useLocalStorage(enumTheme.THEME, enumTheme.DARK_THEME)

    useEffect(() => {
        if(theme === enumTheme.DARK_THEME) document.body.classList.add(enumTheme.DARK_THEME)
        else document.body.classList.remove(enumTheme.DARK_THEME)
    }, [theme])

    return (
        <CustomProvider theme={theme}>
            <ThemeContext.Provider value={[theme, setTheme]}>
                {children}
            </ThemeContext.Provider>
        </CustomProvider>
    )
}