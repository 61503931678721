import InputAmount from "../../../../../../shared/Inputs/InputAmount";
import InputDefault from "../../../../../../shared/Inputs/InputDefault";
import {useState} from "react";
import {useTransfersStore} from "../../../../../../../modules/profile/transfers/store";

const TransferBlock = (props) => {
    const [amountForTransfer, setAmountForTransfer] = useState(100)
    const [steamIdForTransfer, setSteamIdForTransfer] = useState("")
    const isLoading = useTransfersStore((state) => state.isLoading);

    return (
        <>
            <div className="wrapper-input-transfer">
                <InputAmount value={amountForTransfer} setValue={setAmountForTransfer} disablePrompt placeholder={"100"} />
                <InputDefault value={steamIdForTransfer} setValue={setSteamIdForTransfer} placeholder={"example - 76561198121789747"} />
                {/*<div className="clue-transfer">Как правильно перевести баланс?</div>*/}
            </div>
            <div className="wrapper-button-transfer">
                <button
                    disabled={isLoading}
                    onClick={() => props.createTransfer(
                        amountForTransfer,
                        steamIdForTransfer,
                        props.setDataForToaster,
                        setAmountForTransfer,
                        setSteamIdForTransfer,
                        props.fetchUser
                    )}
                    className="button-buy"
                >
                    {isLoading ? "Ожидание ответа..." : "Отправить"}

                </button>
            </div>
        </>
    );
};

export default TransferBlock;