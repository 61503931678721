import "./ProductCardProfile.scss";
import {diffDays} from "../../../../shared/functions/diffDays";
import PopupBasketDesc from "../../ChildPages/Basket/components/PopupBasketDesc/PopupBasketDesc";
import {useState} from "react";

const ProductCardProfile = ({ data, onRemove }) => {
    const [ activePopup, setActivePopup] = useState(false)

    return (
        <>
            <div className="product productBasket" onClick={() => setActivePopup(true)}>
                <img className="product-img" src={data.product.imageUrl} alt="product" />
                <span className="product-server-profile">{data.server.name}</span>
                { data.dateEnd && <span className="product-date-profile">{ diffDays(data.dateEnd) } дней</span> }
            </div>
            <PopupBasketDesc
                activePopup={activePopup}
                setActivePopup={setActivePopup}
                data={data}
                onRemove={onRemove}
            />
        </>
    );
};

export default ProductCardProfile;