import "./ContentProfile.scss";
import {IRoute} from "../../../../routers/interfaces";
import {Redirect, Switch, useLocation} from "react-router-dom";
import PrivateRoute from "../../../../routers/PrivateRoute";
import PersonalProducts from "../../ChildPages/PersonalProducts/PersonalProducts";
import Referral from "../../ChildPages/Referral/Referral";
import Transfer from "../../ChildPages/Transfer/Transfer";
import Basket from "../../ChildPages/Basket/Basket";
import PersonalBonus from "../../ChildPages/PersonalBonus/PersonalBonus";
import Payments from "../../ChildPages/Payments/Payments";
import IndividualSet from "../../ChildPages/IndividualSet/IndividualSet";
import BonusCode from "../../ChildPages/BonusCode/BonusCode";
import {CHILD_PROFILE_PATHS, dataForProfile, dataForProfileAccount} from "../Sidebar/Sidebar";
import {PATHS} from "../../../../routers/utils/Paths.links";
import Footer from "../../../ShopPage/components/Footer/Footer";
import AdminPanel from "../../ChildPages/AdminPanel/AdminPanel";
import AboutAccount from "../../ChildPages/AboutAccount/AboutAccount";

const ContentProfile = () => {
    const ProfileRoutes: IRoute[] = [
        { path: CHILD_PROFILE_PATHS.BASKET, exact: true, component: Basket, withoutAuth: true},
        { path: CHILD_PROFILE_PATHS.PERSONAL_BONUS, exact: true, component: PersonalBonus, withoutAuth: true},
        { path: CHILD_PROFILE_PATHS.TRANSFER, exact: true, component: Transfer, withoutAuth: true },
        { path: CHILD_PROFILE_PATHS.PAYMENTS, exact: true, component: Payments, withoutAuth: true },
        { path: CHILD_PROFILE_PATHS.REFERRAL_PROGRAM, exact: true, component: Referral, withoutAuth: true},
        { path: CHILD_PROFILE_PATHS.INDIVIDUAL_SET, exact: true, component: IndividualSet, withoutAuth: true},
        { path: CHILD_PROFILE_PATHS.BONUS_CODE, exact: true, component: BonusCode, withoutAuth: true },
        { path: CHILD_PROFILE_PATHS.PERSONAL_PRODUCTS, exact: true, component: PersonalProducts, withoutAuth: true },
        { path: CHILD_PROFILE_PATHS.ADMIN_PANEL, exact: true, component: AdminPanel, withoutAuth: true },
        { path: CHILD_PROFILE_PATHS.ACCOUNT, exact: true, component: AboutAccount, withoutAuth: true },
    ]

    const location = useLocation();

    return (
        <div className="contentProfile">
            { dataForProfile.map((item, idx ) =>
                PATHS.PROFILE + item.to === location.pathname &&
                <h2 key={idx} className="tab-title">{item.text}</h2>
            )}
            { dataForProfileAccount.map((item, idx ) =>
                PATHS.PROFILE + item.to === location.pathname &&
                <h2 key={idx} className="tab-title">{item.text}</h2>
            )}
            <div className="contentProfileChild">
                <Switch>
                    { ProfileRoutes.map((route, idx) =>
                        <PrivateRoute
                            {...route}
                            path={PATHS.PROFILE + route.path}
                            key={idx}
                            token={"token"}
                        />
                    )}
                    <Redirect to={CHILD_PROFILE_PATHS.BASKET} />
                </Switch>
            </div>
        </div>
    );
};

export default ContentProfile;