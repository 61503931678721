
const PaginationArrowBtn = ({pageCount, rotate, setActive, active, reduce}) => {
    const funcSwitch = () => {
        if (reduce) {
            setActive( active > 1 ? active - 1 : active )
        } else {
            setActive((prev) => (prev < pageCount ? prev + 1 : prev));
        }
    }

    return (
        <button onClick={() => funcSwitch()} className="pagination-arrow-btn">
            <svg
                transform={rotate && "rotate(180)"}
                className="arrow" xmlns="http://www.w3.org/2000/svg"
                viewBox={`0 ${rotate? "0" : "-150"} 375 835`}
            >
                <g>
                    <path d="M363 304l-292 -292c-17,-16 -43,-16 -59,0 -16,16 -16,42 0,59l263 263 -263 263c-16,16 -16,43 0,59 16,16 42,16 59,0l292 -293c16,-16 16,-42 0,-59z"/>
                </g>
            </svg>
        </button>
    );
};

export default PaginationArrowBtn;