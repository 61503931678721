import {useState} from "react";
import Toaster from "../../../../../shared/Toaster";
import {ToasterProps} from "../../../../../shared/Toaster/Toaster";
import {getCurrentDateInSeconds} from "../../../../../shared/functions/getRandomId";

export interface ISkinMethod {
    name: string,
    style: string,
    img: any
}

const SkinMethod = (props : ISkinMethod) => {
    const { name, style, img } = props

    const [toastProps, setToastProps] = useState<ToasterProps | null>(null);

    return (
        <div
            onClick={() => setToastProps({ message: "Оплата скинами в настоящее время недоступна.", type: "warning", id: getCurrentDateInSeconds() })}
            className="skin-block"
             style={{ background: `${style}`}}
        >
            <div className="skin-card">
                <div className="skin-card-name-block d-flex just-content-space-between">
                    <div>
                        <div>Скины</div>
                        <p> { name } </p>
                    </div>
                    <div className="check-method">
                        +5%
                    </div>
                </div>
                <img src={ img } alt={ name } />
            </div>
            {toastProps && <Toaster message={toastProps.message} type={toastProps.type} id={toastProps.id} />}
        </div>
    );
};

export default SkinMethod;