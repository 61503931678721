import 'rsuite/CheckPicker/styles/index.css';
import 'rsuite/InputPicker/styles/index.css';
import Form from "./components/Form/Form";
import {useState} from "react";
import DropDownPurchase from "../../../../../../shared/DropDowns/DropDownPurchase";

const data = [
    {
        id: 1,
        name: "Создать товар"
    },
    {
        id: 2,
        name: "Редактировать товар"
    },
]


const CreateProduct = () => {
    const [activeProductAction, setActiveProductAction] = useState(data[0])


    return (
        <>
            <div style={{marginBottom: "20px"}}>
                <DropDownPurchase
                    data={data}
                    activeOption={activeProductAction}
                    setActiveOption={setActiveProductAction}
                    width={"245px"}
                />
            </div>
            <h4>Создание товара</h4>
            <div className="admin-wrapper d-flex gap12">
                <div className="admin-wrapper-block">
                    <Form activeProductAction={activeProductAction} />
                </div>
            </div>
        </>
    );
};

export default CreateProduct;