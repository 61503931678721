import ReactDOM from 'react-dom/client'
import App from './app/App.tsx'
import './index.scss'
import {BrowserRouter} from "react-router-dom";

ReactDOM.createRoot(document.getElementById('root')!).render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
)
