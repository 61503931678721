export function formatTime(paymentDate) {
    const hours = new Date(paymentDate).getHours()
    const minutes = new Date(paymentDate).getMinutes()
    const seconds = new Date(paymentDate).getSeconds()
    const time = (hours < 10 ? "0" + hours : hours)  + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds)
    return time;
}

export function formatDate(paymentDate){
    const result = new Date(paymentDate).toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    })
    return result
}