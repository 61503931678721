import "./Header.scss";
import headerImg from "/src/app/assets/header.png";
import qr from "/src/app/assets/qr-new.png";
import {useUserStore} from "../../../../../modules/shop/user/store";

const Header = () => {
    const user = useUserStore(state => state.user)

    return (
        <header>
            <img className="header-img" alt="header" src={headerImg}/>
            <div className="header-info">
                <div className="user-info">
                    {
                        user.personaName ? <>
                            <img className="avatar-user"
                                 src={ user.avatarUrl }
                                 alt="avatar"
                            />
                            <div>
                                <div className="user-block gap12">
                                    <h3 className="user-name">
                                        <strong>{ user.personaName }</strong>
                                    </h3>
                                </div>
                                {
                                    user.personalBonus > 0 ?
                                        <div className="user-title">
                                            Ваш личный бонус +{user.personalBonus}% 👍
                                        </div>
                                        :
                                        <div className="user-title">
                                            У вас пока нет личного бонуса 😕
                                        </div>
                                }
                            </div>
                        </>
                            :
                            <div className="header-info-block">
                                <p className="header-info-block-item">При пополнении от 3к - бонус к пополнению +30%</p>
                                <p className="header-info-block-item">Пожертвования не подлежат возврату.</p>
                            </div>
                    }
                </div>
                <div className="header-qr">
                    <img src={qr} alt="qr" width={120} height={120} className="qr-img"/>
                </div>
            </div>
        </header>
    );
};

export default Header;