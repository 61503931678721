import {PATHS} from "../../../../../../../enum";
import {CHILD_PROFILE_PATHS} from "../../../../components/Sidebar/Sidebar";
import {Link} from "react-router-dom";

const NoProducts = () => {
    return (
        <div className="no-personal-products">
            <h3>
                У вас пока нет ни одного личного товара <span>😕</span>
            </h3>
            <p>Давай исправим это прямо сейчас!</p>
            <div className="personal-block-btn d-flex gap12">
                <Link className='create-set-btn' to={PATHS.PROFILE + CHILD_PROFILE_PATHS.INDIVIDUAL_SET}>
                    Собрать кастом сет
                </Link>
                <button className="personalProducts-button">Заказать уникальный сет</button>
            </div>
        </div>
    );
};

export default NoProducts;