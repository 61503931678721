import {CATEGORY_NAME} from "../../../../../enum";

export const filterCategoryResult = (activeCategory, dataProducts) => {
    return dataProducts.filter((product) => {
        if (activeCategory.name === CATEGORY_NAME.ALL) {
            return true;
        } else {
            const filterProducts: string[] = [];
            product.categories?.map((category) => filterProducts.push(category))
            return !filterProducts.includes(activeCategory._id);
        }
    })
}