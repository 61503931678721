
const InputDefault = ({type, disabled, name, onChange, value, setValue, placeholder}: string) => {
    return (
        <input
            type={type}
            disabled={disabled}
            value={value}
            name={name}
            onChange={onChange ? onChange :(e) => {setValue(e.target.value)}}
            placeholder={placeholder}
            className="input"
        />
    );
};

export default InputDefault;