import Popup from "../../../../shared/Popup";
import InputDefault from "../../../../shared/Inputs/InputDefault";
import {validateEmail} from "../../../../shared/functions/validateEmail";
import axios from "axios";
import {useEffect, useState} from "react";
import {PATHS_API} from "../../../../../enum";
import {useUserStore} from "../../../../../modules/shop/user/store";
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';
import 'rsuite/Tooltip/styles/index.css';
import Toaster from "../../../../shared/Toaster";
import {ToasterProps} from "../../../../shared/Toaster/Toaster";

const PopupDeposit = (props) => {
    const { methodPayment, activePopup, setActivePopup, setActivePopupNotFully, user, amountDeposit, setAmountDeposit} = props
    const fetchUser = useUserStore(state => state.fetchUser)
    const [ emailPlaceholder, setEmailPlaceholder ] = useState("")
    const [ email, setEmail ] = useState("")
    const [toastProps, setToastProps] = useState<ToasterProps | null>(null);

    useEffect(() => {
        setEmailPlaceholder(user.email)
        setAmountDeposit(methodPayment?.min)
    }, [methodPayment])


    const saveEmail = async () => {
        if(!validateEmail(email)) {
            return;
        }

        try {
             await axios.post( PATHS_API.SAVE_EMAIL, {
                    "email": email
                },
                { withCredentials: true })

            setToastProps({ message: "Email сохранён", type: "success", id: new Date() + "" });
        } catch (error) {
            setToastProps({ message: "Ошибка", type: "error", id: new Date() + "" });
        }
        setEmailPlaceholder(email)
        setEmail("")
        fetchUser()
    }

    const createPayment = async () => {
        if(methodPayment){
            if(methodPayment.paymentSystem === "BuyMeACoffee") {
                setActivePopup(false)
                setActivePopupNotFully(true)
                return
            }

            if(!validateEmail(emailPlaceholder)) {
                setToastProps({ message: "Введите ваш Email", type: "warning", id: new Date() + ""});
                if(!validateEmail(email)) return
            }

            try {
                const response = await axios.post( methodPayment.createUrl, {
                        "payload": {
                            "amount": amountDeposit,
                            "prefer_methods": [methodPayment.preferMethod],
                            "customer":{"email":!!email ? email : emailPlaceholder },
                            "paymentSystem": methodPayment.paymentSystem
                        }
                    },
                    { withCredentials: true })

                const data = response.data;
                if (data.payment_url) {
                    window.location.href = data.payment_url; // Редирект на платёжную систему
                }
            } catch (error) {
                console.error('Error creating payment:', error);
            }

        }
    }

    return (
        <Popup title={"Пополнение"} activePopup={activePopup} setActivePopup={setActivePopup}>
            <div className="promo-block">
                <div className="d-flex">
                    { !user.email ?
                        <>
                            <InputDefault value={email} setValue={setEmail} placeholder={"Введите ваш Email"} />
                            <button style={{width: "200px"}} onClick={saveEmail} className="button-buy button-promo">Сохранить</button>
                        </>
                        :
                        <>
                            <InputDefault value={email} setValue={setEmail} placeholder={emailPlaceholder} />
                            <button style={{width: "200px"}} onClick={saveEmail} className="button-buy button-promo">Изменить</button>
                        </>
                    }
                </div>
                <div className="promo-bonus-info">
                    {
                        !user.email
                            ? "Сохраните email для будущих покупок"
                            : "От 3000 ₽ пополнения - бонус к сумме +30%"
                    }

                </div>
            </div>
            <div className="payment-method-wrapper">
                <div className="modal-payment-method d-flex just-content-space-between">
                    <div>
                        <p>
                            Способ оплаты
                        </p>
                        <span>
                            { methodPayment?.name }
                        </span>
                    </div>
                    <img src={methodPayment?.img} alt={methodPayment?.name} />
                </div>
                <div className="modal-payment d-flex">
                    <input value={amountDeposit} onChange={(e) => setAmountDeposit(+e.target.value)} className="modal-payment-input" type="number"/>
                    <span>{ methodPayment?.currency }</span>
                    <div className="icon-payment">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#8595ae">
                            <path
                                d="M15.429 10.073v-4.48c0-.379-.356-.687-.794-.687h-6.95V2.689c0-.607-.845-.917-1.347-.493L.24 7.34a.626.626 0 000 .985l6.098 5.145c.502.423 1.348.115 1.348-.493V10.76h6.949c.438 0 .794-.308.794-.688zm8.331 5.601l-6.098-5.145c-.502-.423-1.348-.115-1.348.493v2.217H9.365c-.438 0-.794.308-.794.688v4.48c0 .38.356.688.794.688h6.95v2.217c0 .609.848.914 1.347.493l6.098-5.145a.626.626 0 000-.986z"></path>
                        </svg>
                    </div>
                    <Whisper
                        placement={"top"}
                        speaker={
                            <Tooltip style={{zIndex: "99999", textAlign: "center"}}>Это сумма которая начислится на баланс с учётом всех бонусов!</Tooltip>
                        }>
                        <input disabled value={amountDeposit} className="modal-payment-input" type="number"/>
                        <span style={{marginRight: "10px"}}>RUB</span>
                    </Whisper>
                </div>
                <div className="fast-buttons">
                    <button onClick={() => setAmountDeposit(500)} className="fast-amount">500 ₽</button>
                    <button onClick={() => setAmountDeposit(1000)} className="fast-amount">1000 ₽</button>
                    <button onClick={() => setAmountDeposit(3000)} className="fast-amount">3000 ₽</button>
                    <button onClick={() => setAmountDeposit(5000)} className="fast-amount">5000 ₽</button>
                </div>
                <button onClick={createPayment} className="button-buy">
                    <div className="d-flex just-content-center ai-center">
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="var(--white-color)">
                            <path d="M19.5 18.19c-2.481 0-4.5-1.922-4.5-4.285 0-2.363 2.019-4.286 4.5-4.286H22V7.714c0-1.05-.897-1.904-2-1.904h-1V3.905C19 2.855 18.103 2 17 2H3C1.517 2 .291 3.032.051 4.38.029 4.458 0 4.534 0 4.619v14.524C0 20.718 1.346 22 3 22h17c1.103 0 2-.854 2-1.905v-1.904h-2.5zM2 4.857c0-.525.448-.952 1-.952h14V5.81H3c-.552 0-1-.428-1-.953z"></path>
                            <path d="M23.25 11.048H19.5c-1.654 0-3 1.281-3 2.857 0 1.575 1.346 2.857 3 2.857h3.75c.414 0 .75-.32.75-.714v-4.286c0-.394-.336-.714-.75-.714zm-3.75 3.81c-.552 0-1-.428-1-.953 0-.526.448-.953 1-.953s1 .427 1 .953-.448.952-1 .952z"></path>
                        </svg>
                        <p style={{marginLeft: "6px"}}>
                            Пополнить
                        </p>
                    </div>
                </button>
            </div>
            {toastProps && <Toaster message={toastProps.message} type={toastProps.type} id={toastProps.id} />}
        </Popup>
    );
};

export default PopupDeposit;