import axios from "axios";
import {PATHS_API} from "../../../../../../enum";

export const getPurchases = (setPurchasesServices, setPurchasesProducts) => {
    axios.get(PATHS_API.PURCHASES,{
        withCredentials: true
    })
        .then((data) => {
            const services = [];
            const products = [];

            data.data?.forEach(purchase => {
                // Правильная проверка на тип продукта
                if (purchase.product.type === "priority" || purchase.product.type === "set") {
                    services.push(purchase);
                } else {
                    products.push(purchase);
                }
            });
            setPurchasesServices(services);
            setPurchasesProducts(products);
        })
}
