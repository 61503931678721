import DropDownPurchase from "../../../../../../shared/DropDowns/DropDownPurchase";
import InputDefault from "../../../../../../shared/Inputs/InputDefault";

const PassProduct = ( { activeProduct, setActiveProduct, updateProductOwners, inputValue, setInputValue, creatorProducts } ) => {
    console.log(activeProduct, setActiveProduct, updateProductOwners, inputValue, setInputValue, creatorProducts)
    return (
        <div className="pass-product">
            <h4 className="title-in-child">Поделиться личным товаром</h4>
            <div className="pass-product-block">
                <DropDownPurchase
                    data={creatorProducts}
                    activeOption={activeProduct}
                    setActiveOption={setActiveProduct}
                    width={"100%"}
                />
                {!!activeProduct._id &&
                    <div className="active-personalProduct">
                        <div className="d-flex flex-direction-column">
                            <div className="give-block">
                                <InputDefault value={inputValue} setValue={setInputValue} placeholder={"SteamID"} />
                                <button onClick={updateProductOwners} className="button-buy">Поделиться</button>
                            </div>
                            <div className="d-flex flex-direction-column">
                                {activeProduct.owners?.length > 0 ?
                                    <h5 className="title-info">Уже имеют доступ к данному товару:</h5>
                                    :
                                    <h5 className="title-info">Доступ к этому товару сейчас только у вас!</h5>
                                }
                                {activeProduct.owners?.map((owner, idx) =>
                                    <div key={idx} className="alert-info-transfer">
                                        <div>{owner}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default PassProduct;