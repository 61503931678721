import "./LevelProfile.scss";
import LevelBlock from "../LevelBlock/LevelBlock";
const LevelProfile = ({user}) => {
    return (
        <div className="level-profile">
            <p>
                Уровень вашего аккаунта:
            </p>
            <LevelBlock user={user} />
        </div>
    );
};

export default LevelProfile;