import "./Pagination.scss";
import ButtonPagination from "./components/Button";
import PaginationArrowBtn from "./components/PaginationArrowBtn";

const Pagination = ({totalItems, active, setActive}) => {
    const pageCount = Math.ceil(totalItems / 6);

    return (
        <div className="pagination">
            { pageCount > 1 && <>
                <PaginationArrowBtn active={active} setActive={setActive} rotate reduce />
                { Array.from({ length: pageCount }, (_, idx) => {
                    const pageNumber = idx + 1;
                    return (
                        <ButtonPagination
                            key={pageNumber}
                            active={active}
                            setActive={setActive}
                            pageNumber={pageNumber}
                        />
                    );
                })}
                <PaginationArrowBtn pageCount={pageCount} active={active} setActive={setActive} />
            </>
            }
        </div>
    );
};

export default Pagination;