import "./IndividualSet.scss";
import DropDownPurchase from "../../../../shared/DropDowns/DropDownPurchase";
import {useState} from "react";
import { InputPicker } from 'rsuite';
import 'rsuite/InputPicker/styles/index.css';


// const data = [{
//         hats: [
//             {
//                 id: "1",
//                 name: "Сет 43"
//             },
//             {
//                 id: "2",
//                 name: "Сет 23"
//             },
//             {
//                 id: "3",
//                 name: "Сет706"
//             }
//         ],
//         masks: [
//             {
//                 id: "1",
//                 name: "Сет 43"
//             },
//             {
//                 id: "2",
//                 name: "Сет 23"
//             },
//             {
//                 id: "3",
//                 name: "Сет706"
//             }
//         ]
//     },
//
// ]

const variantDataInventoryPlus = [
    {
        name: "Нет",
        id: 0
    },
    {
        name: "Да",
        id: 1
    }
]

const dataInventoryPlus = [
    {
        name: "Улучшеный нож(боевой)",
        price: "25 RUB",
    },
    {
        name: "Доп. бинт",
        price: "25 RUB",
    },
    {
        name: "Доп. банка CocaCola",
        price: "50 RUB",
    },
    {
        name: "Розгрузочная система Smersh",
        price: "50 RUB",
    },
]



const IndividualSet = () => {
    const data2 = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
        item => ({ label: item, value: item })
    );

    const [activeHat, setActiveHat] = useState({
        id: "0",
        name: "Выберите вариант"
    })
    const [activeInventoryPlus, setActiveInventoryPlus] = useState({
        id: 0,
        name: "Нет"
    })

    return (
        <div className="individual-set">
            <h3 className="inventory-loot-block__title">Одежда для вашего сета</h3>
            <div className="clue-transfer">Как работает данный сет?</div>
            <div className="cloth-block">
                <h4 className="title-cloth">Головной убор</h4>
                <InputPicker data={data2} block />
            </div>
            <div className="cloth-block">
                <h4 className="title-cloth">Маска</h4>
                <InputPicker data={data2} block />
            </div>
            <div className="cloth-block">
                <h4 className="title-cloth">Очки</h4>
                <InputPicker data={data2} block />
            </div>
            <div className="cloth-block">
                <h4 className="title-cloth">Патч на липучке</h4>
                <InputPicker data={data2} block />
            </div>
            <div className="cloth-block">
                <h4 className="title-cloth">Перчатки</h4>
                <InputPicker data={data2} block />
            </div>
            <div className="cloth-block">
                <h4 className="title-cloth">Торс</h4>
                <InputPicker data={data2} block />
            </div>
            <div className="cloth-block">
                <h4 className="title-cloth">Ноги</h4>
                <InputPicker data={data2} block />
            </div>
            <div className="cloth-block">
                <h4 className="title-cloth">Обувь</h4>
                <InputPicker data={data2} block />
            </div>
            <h3 className="inventory-loot-block__title">Дополнительный инвентарь</h3>
            {
                dataInventoryPlus.map((item) => {
                    return(
                        <div className="cloth-block">
                            <div className="d-flex just-content-space-between ai-center">
                                <h4 className="title-cloth">{item.name}</h4>
                                <span className="price-inventoryPlus-item">
                                    {activeInventoryPlus.id ? "+ " + item.price : ""}
                                </span>
                            </div>
                            <DropDownPurchase
                                data={variantDataInventoryPlus}
                                activeOption={activeInventoryPlus}
                                setActiveOption={setActiveInventoryPlus}
                                width={"100%"}
                            />
                        </div>
                    )
                })
            }
            <div className="wrapper-button-set">
                <button onClick={() => ffff()} className="button-buy">Создать сет  —  450 RUB</button>
            </div>
        </div>
    );
};

export default IndividualSet;