import "./PersonalBonus.scss";
import LevelProfile from "./components/LevelProfile/LevelProfile";
import ProgressLevel from "./components/ProgressLevel/ProgressLevel";
import Bonus from "./components/Bonus/Bonus";
import {useUserStore} from "../../../../../modules/shop/user/store";

const PersonalBonus = () => {
    const user = useUserStore(state => state.user)

    return (
        <>
            <div className="wrapper-bonus">
                <div>
                    <LevelProfile user={user}/>
                    <div className="wrapper-progress-level">
                        <ProgressLevel user={user} />
                    </div>
                </div>
                <Bonus user={user} />
            </div>
            <p className="info-bonus">
                * каждый пройденный уровень увеличивает бонус к пополнению баланса на +1%.
            </p>
        </>

    );
};

export default PersonalBonus;