import {useState} from "react";
import {diffDays} from "../../../../../../shared/functions/diffDays";
import {dataServerList} from "../../../../../ShopPage/components/Products/data";
import Popup from "../../../../../../shared/Popup";
import DropDownPurchase from "../../../../../../shared/DropDowns/DropDownPurchase";
import Toaster from "../../../../../../shared/Toaster";
import axios from "axios";
import {PATHS_API} from "../../../../../../../enum";
import {useUserStore} from "../../../../../../../modules/shop/user/store";
import {useTransfersStore} from "../../../../../../../modules/profile/transfers/store";
import {ToasterProps} from "../../../../../../shared/Toaster/Toaster";

const PopupBasketDesc = (props) => {
    const {data, setActivePopup, activePopup, onRemove} = props
    // const [activeServer, setActiveServer] = useState({id:"0", name: "Сервер"});
    // const [changeServerActive, setChangeServerActive] = useState(false)
    // const [daysForEndReferral, setDaysForEndReferral] = useState(diffDays(data.dateEnd))
    const fetchUser = useUserStore(store => store.fetchUser)

    const [isLoading, setIsLoading] = useState(false)
    const [toastProps, setToastProps] = useState<ToasterProps | null>(null);

    const refund = async (_id: string) => {
        if (isLoading) return; // предотвращает повторные клики
        setIsLoading(true)

        await axios.put(PATHS_API.PURCHASES, { _id }, { withCredentials: true })
            .then(async (data) => {
                setToastProps({
                    message: data.data.message,
                    type: data.data.status === 201 ? "success" : "error",
                    id: data.data.id
                })
                fetchUser()
                await onRemove();
            })
            .catch((error) => {
                // В случае ошибки показываем сообщение об ошибке
                const errorMessage = error.response?.data?.message || "Произошла ошибка";
                setToastProps({ message: errorMessage, type: "error", id: error.response?.data?.id });
                setActivePopup(false);
            })
            .finally(() => {
                setIsLoading(false);
                setActivePopup(false);
            });
    }

    return (
        <>
            <Popup
                title={data.product.productName}
                setActivePopup={setActivePopup}
                activePopup={activePopup}
            >
                <div className="card-product-profile">
                    <img className="product-card-profile product-img" src={data.product.imageUrl} alt="product-img"/>
                    <div className="purchase-info">
                        <p className="desc-product">
                            Вы можете вернуть данный товар в любое время
                        </p>
                        <div className="d-flex gap12">
                            <div className="d-flex flex-direction-column">
                                <button onClick={() => {
                                    refund(data._id)
                                    setActivePopup(false)
                                }} className="button-buy change-server-btn">
                                    {
                                        isLoading ? "Ожидание ответа..." : "Сделать возврат товара"
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
            {toastProps && <Toaster message={toastProps.message} type={toastProps.type} id={toastProps.id} />}
        </>
    )
};

export default PopupBasketDesc;