import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useLayoutEffect(() => {
        // Прокрутить страницу в верх при изменении маршрута
        window.scrollTo(0, 0);
    }, [pathname]); // Следим за изменениями пути

    return null;
};

export default ScrollToTop;

